import { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth/AuthProvider'
import { ClaimComment } from '../models/ClaimComment'
import { ClaimCommentView } from '../models/ClaimCommentView'
import { CommentsContext } from './ClaimDetails'
import { Context } from './ClaimDetails/ClaimDetailsReviewInfoCard'
import theme from '../themes/muiTheme'
import {
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { ClaimsApi } from '../api/ClaimsApi'
import { PostClaimCommentResponse } from '../models/api/PostClaimCommentResponse'
import ErrorSnackbar from './ErrorSnackbar'
import React from 'react'

const ClaimlineCommentButton = (props: { claimDetailsData: any }) => {
  const claimDetailsData = props.claimDetailsData

  const commentsContext = useContext(CommentsContext)
  const navigate = useNavigate()

  const { lineNumber, lineNumberId } = useContext(Context)

  const authContext = useContext(AuthContext)
  const [isUserCaas, setUserCass] = useState(
    authContext.isUserCaas(authContext.cognitoAuth)
  )

  const [visibleBox, setVisibleBox] = useState<boolean>(false)
  const [commentText, setCommentText] = useState('')
  const [userTenantId, setUserTenantId] = useState('')
  const [open, setOpen] = useState(false)

  const { search } = useLocation()
  const searchParam = React.useMemo(() => new URLSearchParams(search), [search])

  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setCommentText('')
    setOpen(false)
  }

  useEffect(() => {
    if (isUserCaas && searchParam.get('clinician') == 'javalin') {
      setUserTenantId(localStorage.getItem('caas_tenant') || '""')
    } else {
      setUserTenantId(authContext.getTenantId(authContext.cognitoAuth))
    }
  }, [authContext])

  const toggleVisibleBox = () => {
    setVisibleBox(!visibleBox)
  }

  const handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(e.target.value)
  }

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }

  const claimCommentToView = (comment: ClaimComment): ClaimCommentView => {
    const commentView: ClaimCommentView = {
      lineNumber: comment.lineNumber,
      comments: comment.comment,
      commentDate: comment.commentDate,
      user: authContext.getUserDisplayName(authContext.cognitoAuth),
    }
    return commentView
  }

  const handleCommentSave = async () => {
    const { userCommentsList } = commentsContext

    if (
      commentText &&
      claimDetailsData.claimlines &&
      claimDetailsData.claimlines.length > 0
    ) {
      const claimlineComment: ClaimComment = {
        tenantId: userTenantId,
        comment: commentText,
        claimlineId: lineNumberId.toString(),
        commentDate: new Date().toJSON(),
        lineNumber: lineNumber,
      }

      if (!userTenantId) {
        setErrorSnackbarProps({
          errorMessage: 'Failed to Add Comment.',
          isOpenErrorSnackbar: true,
          title: 'Authentication issue.',
        })
        return
      }

      const response: PostClaimCommentResponse =
        await ClaimsApi.postClaimComment(claimlineComment)

      if (
        !response.apiRequestStatusNumber ||
        String(response.apiRequestStatusNumber)[0] !== '2'
      ) {
        if (response.apiRequestStatusNumber === 401) {
          localStorage.setItem(
            'requestedUrl',
            window.location.pathname + window.location.search
          )
          navigate('/login', { replace: true })
        } else {
          setErrorSnackbarProps({
            errorMessage: response.apiRequestStatusText,
            isOpenErrorSnackbar: true,
            title: `Request status: ${
              response.apiRequestStatusNumber ?? 'unknown'
            }`,
          })
        }
        return
      }

      if (userCommentsList) {
        userCommentsList.unshift(claimCommentToView(claimlineComment))
        commentsContext.updateComments([...userCommentsList])
      } else {
        commentsContext.updateComments([claimCommentToView(claimlineComment)])
      }
      toggleVisibleBox()
    }
    setCommentText('')
  }

  return (
    <div>
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent>
          <TextField
            inputProps={{ 'data-qa': 'textarea-comment-text' }}
            data-qa="input-comment"
            id="commentInput"
            type="text"
            multiline
            variant="filled"
            rows={4}
            fullWidth
            autoFocus
            value={commentText}
            onChange={handleCommentsChange}
          />
        </DialogContent>

        <DialogActions>
          <Button
            data-qa="button-dialog-cancel"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-qa="button-dialog-save"
            variant="contained"
            onClick={handleCommentSave}
          >
            Save
          </Button>
        </DialogActions>
        <ErrorSnackbar
          errorMessage={errorSnackbarProps.errorMessage}
          title={errorSnackbarProps.title}
          isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
          handleCloseSnackbar={handleErrorSnackbarClose}
        ></ErrorSnackbar>
      </Dialog>
      <div>
        <Tooltip title="Add Comment" placement="left">
          <IconButton
            color="primary"
            data-qa={'button-add-comment'}
            onClick={handleClickOpen}
            sx={{ padding: 0 }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: `${theme.palette.primary.main}`,
                color: 'white',
                padding: '8px 20px',
                borderRadius: '4px',
              }}
            >
              <span
                style={{
                  fontSize: '32px',
                  lineHeight: '22px',
                  marginBottom: '2px',
                }}
              >
                +
              </span>
              <span
                style={{
                  fontSize: '14px',
                  lineHeight: '14px',
                  letterSpacing: '0.1em',
                  fontWeight: 'bold',
                  marginLeft: '10px',
                }}
              >
                REVIEW
              </span>
            </div>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default ClaimlineCommentButton
