import '../sass/base/_global.scss'
import '../sass/components/_login.scss'
import { useNavigate } from 'react-router-dom'
import { FormControl, SelectChangeEvent, Select, MenuItem } from '@mui/material'
import { ITenant } from '../models/api/ITenant'
import { useState, useEffect } from 'react'
import { GetCaasTenantsResponse } from '../models/api/GetCaasTenantsResponse'
import { CaasApi } from '../api/CaasApi'
import ErrorSnackbar from './ErrorSnackbar'

const TenantSelection = () => {
  const navigate = useNavigate()
  const [tenants, setTenants] = useState<ITenant>({})
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }

  const selectTenant = (event: SelectChangeEvent) => {
    localStorage.setItem('caas_tenant', event.target.value)
    navigate(`/caas/claims`)
  }

  const getTenants = async () => {
    setTenants({})

    let getTenantsResponse: GetCaasTenantsResponse = await CaasApi.getTenants()
    if (
      !getTenantsResponse.apiRequestStatusNumber ||
      String(getTenantsResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(getTenantsResponse)
      setErrorSnackbarProps({
        errorMessage: getTenantsResponse.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${
          getTenantsResponse.apiRequestStatusNumber ?? 'unknown'
        }`,
      })
      return
    }

    setTenants(getTenantsResponse.tenants)
  }

  useEffect(() => {
    getTenants()
  }, [])

  return (
    <div
      className="tenant-list-container"
      style={{ padding: '100px', height: '400px' }}
    >
      <div style={{ margin: 'auto', width: '55%' }}>
        <div style={{ margin: 'auto' }}>
          <span style={{ fontWeight: 600, fontSize: 18, height: '22px' }}>
            Select a tenant:
          </span>
        </div>
        <FormControl fullWidth>
          <Select
            data-qa="select-tenant-dropdown-page"
            size="medium"
            onChange={selectTenant}
            style={{ backgroundColor: '#FFF' }}
            displayEmpty
            renderValue={() => 'Select...'}
          >
            {Object.entries(tenants!).map((array) => {
              return (
                <MenuItem key={array[0]} value={array[0]}>
                  {array[1]}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <ErrorSnackbar
        errorMessage={errorSnackbarProps.errorMessage}
        title={errorSnackbarProps.title}
        isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
        handleCloseSnackbar={handleErrorSnackbarClose}
      />
    </div>
  )
}

export default TenantSelection
