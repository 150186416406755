import { AxiosError } from 'axios'
import { GetCaasClaimsResponse } from '../models/api/GetCaasClaimsResponse'
import { CaasClaimsFilter } from '../models/CaasClaimsFilter'
import api from './config/axiosConfig'
import { IApiResponse } from '../models/api/IApiResponse'
import { AxiosErrorResponse } from '../models/api/AxiosErrorResponse'
import { GetCaasTenantsResponse } from '../models/api/GetCaasTenantsResponse'
import { PostCAASAdjudicationResponse } from '../models/api/PostCAASAdjudicationResponse'

export interface ICaasApi {
  getClaims: (claimsFilter: CaasClaimsFilter) => Promise<GetCaasClaimsResponse>
  getTenants: () => Promise<GetCaasTenantsResponse>
  postCAASAdjudication: (
    tenantId: string,
    claimLineId: string,
    CAASAdjudication: string,
    CaasAdjudicationInformation: string,
    CaasAdjudicationDowncode: string
  ) => Promise<PostCAASAdjudicationResponse>
}

export const CaasApi: ICaasApi = {
  getTenants: async (): Promise<GetCaasTenantsResponse> => {
    try {
      const response = await api.get('caas/tenants')
      return {
        ...response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ...new GetCaasTenantsResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },
  getClaims: async (
    claimsFilter: CaasClaimsFilter
  ): Promise<GetCaasClaimsResponse> => {
    try {
      const response = await api.get('caas/getClaims/', {
        params: {
          tenantId: claimsFilter.tenantId,
          SubmitDateFrom: claimsFilter.submitDateFrom,
          SubmitDateTo: claimsFilter.submitDateTo,
          PageNumber: claimsFilter.pageNumber,
          PageSize: claimsFilter.pageSize,
          IsAssigned: claimsFilter.isAssigned,
          IsAdjudicated: claimsFilter.isAdjudicated,
          SortOrder: claimsFilter.sortOrder,
          SortField: claimsFilter.sortField,
          ...(claimsFilter.isAppeal !== undefined
            ? { IsAppeal: claimsFilter.isAppeal }
            : {}),
          ...(claimsFilter.claimNumber && claimsFilter.claimNumber !== '%20'
            ? { ClaimNumber: claimsFilter.claimNumber }
            : {}),
          ...(claimsFilter.groupName && claimsFilter.groupName !== '%20'
            ? { GroupName: claimsFilter.groupName }
            : {}),
          ...(claimsFilter.renderingProvider &&
          claimsFilter.renderingProvider !== '%20'
            ? { RenderingProvider: claimsFilter.renderingProvider }
            : {}),
          ...(claimsFilter.sortField && claimsFilter.sortField !== '%20'
            ? { SortField: claimsFilter.sortField }
            : {}),
          ...(claimsFilter.userName && claimsFilter.userName !== ''
            ? { UserName: claimsFilter.userName }
            : {}),
        },
      })
      return {
        ...response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ...new GetCaasClaimsResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  postCAASAdjudication: async (
    tenantId: string,
    claimLineId: string,
    CAASAdjudication: string,
    CaasAdjudicationInformation: string,
    CaasAdjudicationDowncode: string
  ): Promise<PostCAASAdjudicationResponse> => {
    try {
      const response = await api.post(
        `/caas/saveClinicalAdjudication`,
        JSON.stringify({
          tenantId: tenantId,
          claimlineId: claimLineId,
          CAASAdjudication: CAASAdjudication,
          CaasAdjudicationInformation: CaasAdjudicationInformation,
          CaasAdjudicationDowncode: CaasAdjudicationDowncode,
        })
      )
      return new PostCAASAdjudicationResponse(
        response.statusText,
        response.status
      )
    } catch (error) {
      console.log(error)
      return {
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },
}

function getIApiResponseFromError(
  error: AxiosError<AxiosErrorResponse, any>
): IApiResponse {
  if (error.response) {
    const finalErrorMessage =
      error.response.data.message ??
      error.message ??
      'Unknown Error Encountered'
    return {
      apiRequestStatusText: finalErrorMessage,
      apiRequestStatusNumber: error.response.status,
    }
  }
  if (error.message) {
    // hack to solve bug 130239 since no better solution has been found yet
    if (error.message === 'Network Error')
      return {
        apiRequestStatusText:
          'Cannot complete the request. Try logging out and back in.',
        apiRequestStatusNumber: 401,
      }
    else {
      return {
        apiRequestStatusText: error.message,
      }
    }
  }
  return {
    apiRequestStatusText: 'Unknown Error Encountered',
  }
}
