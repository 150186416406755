import React, { useState, useRef, useContext, useEffect } from 'react'
import { Button, TextField } from '@mui/material'
import { AuthContext } from '../auth/AuthProvider'
import { MdOutlineFileUpload } from 'react-icons/md'
import { IoDocumentTextSharp } from 'react-icons/io5'

import '../sass/components/assign/_assign.scss'

export function Assign() {
  const authContext = useContext(AuthContext)
  const [token, setToken] = useState('')
  const [idToken, setIdToken] = useState('')
  const [isOver, setIsOver] = useState(false)
  const [isFileTypeValid, setIsFileTypeValid] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [selectedFileName, setSelectedFileName] = useState('')
  const [userTenantId, setUserTenantId] = useState('')
  const [dragMessage, setDragMessage] = useState(
    'Select file or drag and drop a file into the shaded area.'
  )
  const [messageNotCompatible] = useState(
    'File type not compatible. Please use a CSV file.'
  )
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const baseUrl = process.env.REACT_APP_API_USER_URL

  useEffect(() => {
    setToken(authContext.getAccessToken(authContext.cognitoAuth))
    setIdToken(authContext.getIdentityToken(authContext.cognitoAuth))
  }, [authContext])

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(true)
    setDragMessage('Drop file now')
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(false)
    setDragMessage('Select file or drag and drop a file into the shaded area.')
  }

  useEffect(() => {
    setUserTenantId(authContext.getTenantId(authContext.cognitoAuth))
  }, [authContext])

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(false)

    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files)
    setFiles(droppedFiles)

    if (droppedFiles.length > 0) {
      const fileName = droppedFiles[0].name
      setSelectedFileName(fileName)
    }

    // Use FileReader to read file content
    droppedFiles.forEach((file) => {
      if (isValidFile(file)) {
        const reader = new FileReader()

        reader.onerror = () => {
          setDragMessage('There was an issue reading the file.')
        }

        reader.readAsText(file)
        setDragMessage('File ready for upload.')
      } else {
        setDragMessage(messageNotCompatible)
      }
    })
  }

  const isValidFile = (file: File) => {
    switch (file.type) {
      case 'text/csv':
        setIsFileTypeValid(true)
        return true
      default:
        setDragMessage('File type unsupported: ' + file.type)
        setIsFileTypeValid(false)
        return false
    }
  }

  const handleSelectFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileName = e.target.files?.[0]?.name || ''
    if (fileName !== '' && e.target.files?.[0]?.name) {
      setSelectedFileName(e.target.files[0].name)

      if (isValidFile(e.target.files[0])) {
        setDragMessage('File ready for upload')
        setFiles(Array.from(e.target.files))
      } else {
        setDragMessage(messageNotCompatible)
      }
    }
  }

  const handleUpload = () => {
    if (files.length === 0) {
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      try {
        const csvData = reader.result as string
        const errorMessageForEmptyFile = 'The file is empty'
        if (csvData.trim() === '') {
          setDragMessage(errorMessageForEmptyFile)
          return
        }
        const parsedData = parseCSV(csvData)
        if (parsedData.length === 0) {
          setDragMessage(errorMessageForEmptyFile)
          return
        }
        postCSVData(parsedData)
      } catch (error) {
        if (error instanceof Error) {
          setDragMessage(error.message)
        } else {
          setDragMessage('An error occurred while processing the CSV data.')
        }
      }
    }

    reader.readAsText(files[0])
  }

  const parseCSV = (
    csvData: string
  ): Array<{ Claim: string; User: string }> => {
    const lines = csvData.split('\n')
    const header = lines[0].split(',')

    const parsedData = []

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',')

      if (values.length === header.length) {
        const entry: { Claim: string; User: string } = {
          Claim: values[0].trim(),
          User: values[1].trim(),
        }

        if (entry.Claim === '' && entry.User === '') {
          continue
        }

        if (entry.User === '') {
          throw new Error(`The user name for ${entry.Claim} claim is empty`)
        }

        if (entry.Claim === '') {
          throw new Error(`The claim for ${entry.User} is empty`)
        }
        parsedData.push(entry)
      }
    }

    return parsedData
  }

  const postCSVData = async (data: Array<{ Claim: string; User: string }>) => {
    try {
      const url = new URL(
        '/claims/assignment?tenantId=' + userTenantId,
        baseUrl
      )

      let callHeaders = new Headers()
      callHeaders.append('Authorization', 'Bearer ' + token)
      callHeaders.append('Identity', idToken)
      callHeaders.append('Content-Type', 'application/json')

      let requestOptions = {
        method: 'POST',
        headers: callHeaders,
        body: JSON.stringify(data),
      }

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(`${errorData.message}`)
            })
          }
          return response.text()
        })
        .then(() => {
          setDragMessage('CSV data uploaded successfully')
        })
        .catch((error) => {
          setDragMessage(error.message)
        })
    } catch (error) {
      setDragMessage('Error posting CSV data:' + error)
    }
  }

  return (
    <div
      className="app"
      style={{
        margin: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        data-qa="div-assign-container"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          display: 'flex',
          borderRadius: '5px',
          border: '2px dashed #41a928',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexWrap: 'nowrap',
          backgroundColor: isOver ? '#eef6ec' : '#fff',
          flexDirection: 'column',
          width: '500px',
          padding: '20px',
          margin: '10px',
        }}
      >
        <h1 data-qa="h1-assign-header" style={{ fontWeight: 'bold' }}>
          <IoDocumentTextSharp style={{ color: '#41a928', fontSize: '2em' }} />{' '}
          Upload Claim Assignment List
        </h1>
        <div data-qa="div-drag-message">{dragMessage}</div>
        <div
          data-qa="div-inner-container"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <label htmlFor="file_assignment" data-qa="label-file-assignment">
            <TextField
              type="text"
              id="file_assignment"
              size="small"
              value={selectedFileName}
              hidden
            />
            <Button
              data-qa="button-select-file"
              component="span"
              variant="contained"
              color="primary"
              onClick={handleSelectFile}
              sx={{
                fontSize: '0.9em',
                fontWeight: 'bold',
                padding: '0 20px',
                margin: '20px',
              }}
            >
              <h3>
                <MdOutlineFileUpload />
              </h3>
              <span style={{ marginLeft: '10px' }}>Select File</span>
            </Button>
            <input
              data-qa="input-hidden-file-input"
              ref={fileInputRef}
              type="file"
              id="hidden_file_input"
              accept="text/plain,text/csv"
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
            <div
              data-qa="div-selected-filename"
              style={{
                marginLeft: '10px',
                marginTop: '10px',
                fontFamily: 'Courier New, Courier, monospace',
              }}
            >
              {selectedFileName}
            </div>
          </label>
        </div>
      </div>
      <div data-qa="div-upload-button-container">
        {isFileTypeValid ? (
          <Button
            data-qa="button-upload"
            variant="contained"
            color="primary"
            onClick={handleUpload}
          >
            Upload
          </Button>
        ) : null}
      </div>
    </div>
  )
}
