import '../sass/base/_global.scss'
import '../sass/components/_login.scss'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth/AuthProvider'
import LockIcon from '@mui/icons-material/Lock'
import { Button } from '@mui/material'
import PopupMessage from './PopupMessage'

const Login = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const showMsg = !!localStorage.getItem('hasHistory')
  const isUserSignedIn = authContext.cognitoAuth.isUserSignedIn()
  const login = () => {
    if (isUserSignedIn) {
      const savedRoute = localStorage.getItem('requestedUrl')
      if (savedRoute) {
        localStorage.removeItem('requestedUrl')
        navigate(savedRoute)
      } else {
        navigate('/claims')
      }
    } else {
      authContext.cognitoAuth.getSession()
    }
  }

  return (
    <div className="login" style={{ padding: '100px' }}>
      {showMsg ? (
        <PopupMessage
          msg="You have been logged out. Please sign in."
          title="Session Timeout"
          dataQaAttributeAlert="session-timeout-popup-message"
          dataQaAttributeTextDiv="session-timeout-popup-text-div"
        />
      ) : null}
      <Button
        className="login-btn"
        variant="contained"
        color="primary"
        onClick={login}
        sx={{ width: '150px' }}
      >
        <LockIcon />
        <span
          style={{ marginLeft: '10px', fontWeight: 'bold', fontSize: '1.2em' }}
        >
          Login
        </span>
      </Button>
    </div>
  )
}

export default Login
