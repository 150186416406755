import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../sass/base/_global.scss'
import '../../sass/components/details/_details-image-gallery.scss'
import { ClaimImage } from '../../models/ClaimImage'
import {
  Carousel,
  CarouselItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import classnames from 'classnames'
import error from '../../images/error.png'
import { AuthContext } from '../../auth/AuthProvider'
import { styled } from '@mui/material/styles'
import { Slider, Button } from '@mui/material'
import { ImEnlarge } from 'react-icons/im'
import { MdOutlineRotateLeft, MdOutlineRotateRight } from 'react-icons/md'
import { ClaimsApi } from '../../api/ClaimsApi'
import ErrorSnackbar from '../ErrorSnackbar'
import { GetImageResponse } from '../../models/api/GetImageResponse'
import { ColorChartList } from './ColorChart/ColorChartList'
import { useLocation } from 'react-router-dom'

interface ClaimDetailsInfo {
  tenantId: string
  claimNumber: string
}

const StyledSlider = styled(Slider)({
  '&  .MuiSlider-valueLabel': {
    background: 'none',
    color: '#000000',
    fontSize: '10px',
  },
})

const ClaimDetailsImageGallery = (props: {
  claimDetailsInfo: ClaimDetailsInfo
}) => {
  const narrativeDefault = 'Image does not possess narrative.'

  const { claimDetailsInfo } = props
  const [images, updateImages] = useState(new Array<ClaimImage>(0))
  const [imageNames, updateImageNames] = useState(new Array<String>(0))
  const [activeIndex, updateActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [zoomOpen, setZoomOpen] = useState(false)
  const [timeOutError, setTimeoutError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [claimNarrative, setClaimNarrative] = useState(narrativeDefault)
  const [scale, setScale] = useState(1)
  const [brightness, setBrightness] = useState(100)
  const [contrast, setContrast] = useState(100)
  const [rotation, setRotation] = useState(0)
  const [position, setPosition] = useState({
    x: 50,
    y: 50,
  })
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
    
  const authContext = useContext(AuthContext)
  const [isUserCaas, setUserCass] = useState(
    authContext.isUserCaas(authContext.cognitoAuth)
  )
  const { search } = useLocation()
  const searchParam = React.useMemo(() => new URLSearchParams(search), [search])

  const [userTenantId, setUserTenantId] = useState('')

  const myimage = React.useRef(null)

  // Updates scale value
  const handleScale = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number)
  }

  // Updates brightness value
  const handleBrightness = (event: Event, newValue: number | number[]) => {
    const value = Math.round(newValue as number)
    setBrightness(value)
  }

  // Updates contrast value
  const handleContrast = (event: Event, newValue: number | number[]) => {
    const value = Math.round(newValue as number)
    setContrast(value)
  }

  // Rotates image for image enhancement
  const rotateLeft = () => {
    let updatedRotation = rotation - 90
    if (updatedRotation >= 360) {
      updatedRotation = -360
    }
    setRotation(updatedRotation)
  }

  // Rotates image for image enhancement
  const rotateRight = () => {
    let updatedRotation = rotation + 90
    if (updatedRotation >= 360) {
      updatedRotation = -360
    }
    setRotation(updatedRotation)
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1
    updateActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1
    updateActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: React.SetStateAction<number>) => {
    if (animating) return
    updateActiveIndex(newIndex)
  }

  const changeFocusImage = (
    newIndex: React.SetStateAction<number>,
    newNarrative: React.SetStateAction<string>
  ) => {
    goToIndex(newIndex)
    if (newNarrative) {
      setClaimNarrative(newNarrative)
    } else {
      setClaimNarrative(narrativeDefault)
    }
  }

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const toggleZoomin = () => {
    setScale(1)
    setBrightness(100)
    setContrast(100)
    setRotation(0)
    setZoomOpen(!zoomOpen)
    // clear the position so it does not persist previous between modal invocations
    setPosition({
      x: 0,
      y: 0,
    })
  }

  const [clicked, setClicked] = useState(false)
  const [pos, setPos] = useState({ x: 0, y: 0 })
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${pos.x}px, ${pos.y}.px)`
    }
  }, [pos])

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (clicked && ref.current) {
      const parentRect = ref.current.parentElement?.getBoundingClientRect()
      const elementRect = ref.current.getBoundingClientRect()
      const newX = event.clientX - parentRect!.left - elementRect.width / 2
      const newY = event.clientY - parentRect!.top - elementRect.height / 2
      setPosition({ x: newX, y: newY })
    }
  }

  /**
   * Creates the thumbnail to display if the ClaimImage contains an image, otherwise display the NoImage image.
   * @param item Claim Image to process
   * @param index The current index for the <ol> element.
   * @returns A <li> element.
   */
  const createThumbnail: any = (item: ClaimImage, index: number) => {
    let backgroundUrl = ''
    if (item.content) {
      backgroundUrl = `url(${`data:image/jpg;image/png;base64,${item.content}`})`
    } else {
      // empty image, is a bad image
      backgroundUrl = `url(${error})`
    }
    return (
      <>
        <li
          data-qa={'li-' + index}
          key={'li-' + index}
          id={'li-' + index}
          style={{marginRight: '20px'}}
          onClick={() => changeFocusImage(index, item.narrative)}
          className={classnames({
            active: activeIndex === index,
            'carousel-thumbnail-tooltip': true,
          })}
        >
          <span style={{
            border: activeIndex === index ? '2px solid white' : 'none',
            minHeight: '70px',
            minWidth: '90px',
            margin: '5px',
            backgroundImage: backgroundUrl,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            display: 'block',
          }}></span>  
        </li>
      </>
    )
  }

  /**
   * Creates the image for the carousel component if the ClaimImage contains a base64 encoded image.
   * Otherwise function will return the NoImage image.
   * @param item Claim Image to process
   * @returns Div element of custom Img and Narrative
   */
  const createCarouselImage: any = (item: ClaimImage) => {
    let imgSrc = ''
    if (item.content) {
      imgSrc = `data:image/png;image/jpg;base64,${item.content}`
    } else {
      imgSrc = error
      // If the attempted image to populate hits a timeout error, and the API failed to grab any images.
      if ((timeOutError || !loading) && imageNames.length === 0) {
        return (
          <div className='carousel-image-container' data-qa={'carousel-image-container'}>
            <img
              data-qa={'img-error'}
              className="carousel-item-image"
              src={error}
              alt={error}
            />
            <p>
              Images are not available at this time. <br />
              Please click{' '}
              <a data-qa={'a-refresh'} href="">
                refresh
              </a>{' '}
              and try again.
            </p>
          </div>
        )
      }
    }
    return (
      <div className='carousel-image-container' data-qa={'carousel-image-container'}>
        <img
          data-qa={'img-item'}
          className="carousel-item-image"
          src={imgSrc}
          alt={error}
        />
      </div>
    )
  }

  /**
   * Creates a single carousel item(main item in carousel) if the image exists.
   * Enlarge button and narrative only displays if the image exists.
   * @param item ClaimImage to process
   * @returns CarouselItem Component to load
   */
  const createCarouselItem: any = (item: ClaimImage) => {
    return (
      <CarouselItem
        onExiting={onExiting}
        onExited={onExited}
        key={item.fileName ? item.fileName : 'error'}
      >
        <div className="carousel-grid">
          <div className="carousel-top">
            <h3 data-qa={'h3-images'} style={{ color: '#ffffff', fontSize: '24px' }}>
              Image Gallery
            </h3>
            <div className="carousel-enlarge-alignment">
              <Button data-qa={'button-full-screen'} onClick={toggleZoomin}>
                <ImEnlarge /> Enlarge
              </Button>
            </div>
          </div>

          {createCarouselImage(item)}
          {imageNames.length > 0 && (
            <div className="carousel-color-key">
              {item.fileName.includes('annotated') && (
                <div data-qa={'color-key-container'} className="color-key">
                  <ColorChartList />
                </div>
              )}
            </div>
          )}
        </div>
      </CarouselItem>
    )
  }

  /**
   * This loads the main Carousel Component
   * @returns Carousel Component if loading is done, otherwise spinner.
   */
  const loadCarousel: any = () => {
    if (loading) {
      // Load icon for when the set of image names has not returned.
      return (
        <div data-qa={'div-loading-carousel'} className="loading-carousel">
          <Spinner />
        </div>
      )
    }

    // Configure carousel thumbnails if images are available
    let carouselThumbnail = null
    if (imageNames.length === 0) {
      carouselThumbnail = <div />
    } else {
      carouselThumbnail = (
        <div>
          <div className="carousel-thumbnail-scroll">
            <ol className="carousel-indicators">
              {/* Dynamic background for thumbnail on carousel indicators, need in-line styling to override bootstrap */}
              {images.map((item, index) => {
                return createThumbnail(item, index)
              })}
            </ol>
          </div>
          <div className="narrative-copy">
            <h6 data-qa={'h6-narrative'}>
              NARRATIVE
            </h6>
              <p data-qa={'p-claim-narrative'}>{claimNarrative}</p>
          </div>
        </div>
      )
    }

    return (
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
      >
        {images.map((item) => {
          return createCarouselItem(item)
        })}
        {carouselThumbnail}
      </Carousel>
    )
  }

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }

  /**
   * Used in Effect() to update current state of images when Component Mounts.
   */
  const updateStateFromService = useCallback(async () => {
    const getImageNamesResponse = await ClaimsApi.getImageNames(
      userTenantId,
      claimDetailsInfo.claimNumber
    )
    if (
      !getImageNamesResponse.apiRequestStatusNumber ||
      String(getImageNamesResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(getImageNamesResponse)
      setErrorSnackbarProps({
        errorMessage: getImageNamesResponse.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${
          getImageNamesResponse.apiRequestStatusNumber ?? 'unknown'
        }`,
      })
      setLoading(false)
      updateImages([new ClaimImage()])
      return
    }

    // Failed to get list of images for this claim.
    if (getImageNamesResponse.imageNamesList.length === 0) {
      // Create an empty claimImage to display as 'no image'
      updateImages([new ClaimImage()])
    } else {
      const claimImagesList = Array<GetImageResponse>()
      const intactImagesPaths = Array<string>()
      // Fetch each image(narrative and content) async
      for (
        let index = 0;
        index < getImageNamesResponse.imageNamesList.length;
        index++
      ) {
        const pathItemsArray =
          getImageNamesResponse.imageNamesList[index].split('/')
        const fileName = pathItemsArray[pathItemsArray.length - 1]
        const imageCategory = pathItemsArray[pathItemsArray.length - 2]

        const getImageResponse: GetImageResponse = await ClaimsApi.getImage(
          userTenantId,
          claimDetailsInfo.claimNumber,
          imageCategory,
          fileName
        )
        if (
          !getImageResponse.apiRequestStatusNumber ||
          String(getImageResponse.apiRequestStatusNumber)[0] !== '2'
        ) {
          console.error(getImageResponse)
          setErrorSnackbarProps({
            errorMessage: getImageResponse.apiRequestStatusText,
            isOpenErrorSnackbar: true,
            title: `Request status: ${
              getImageResponse.apiRequestStatusNumber ?? 'unknown'
            }`,
          })
          setLoading(false)
          updateImages([new ClaimImage()])
          return
        }

        // might cause a problem. The EOI bytes ( i.e. 255, 217 ) doesn't always happen to be the last bytes.
        // if stops working, find a more graceful solution
        // ref https://stackoverflow.com/questions/41462108/js-check-if-an-image-truncated-corrupted-data
        const imageData = Uint8Array.from(
          atob(getImageResponse.content.replace('data:image/jpeg;base64,', '')),
          (c) => c.charCodeAt(0)
        )
        var isImageCorrupted = false
        if (getImageResponse.contentType === 'image/jpeg') {
          isImageCorrupted =
            imageData[imageData.length - 1] !== 217 ||
            imageData[imageData.length - 2] !== 255 ||
            imageData[0] !== 255 ||
            imageData[1] !== 216
        } else if (getImageResponse.contentType === 'image/png') {
          isImageCorrupted =
            imageData[imageData.length - 1] !== 130 ||
            imageData[imageData.length - 2] !== 96 ||
            imageData[imageData.length - 3] !== 66 ||
            imageData[imageData.length - 4] !== 174 ||
            imageData[imageData.length - 5] !== 68 ||
            imageData[imageData.length - 6] !== 78 ||
            imageData[imageData.length - 7] !== 69 ||
            imageData[imageData.length - 8] !== 73
        }

        if (isImageCorrupted) continue

        claimImagesList.push(getImageResponse)
        intactImagesPaths.push(getImageNamesResponse.imageNamesList[index])
      }
      //make sure first narrative shown is the one that corresponds to the first image
      if (claimImagesList[0].narrative) {
        setClaimNarrative(claimImagesList[0].narrative)
      } else {
        setClaimNarrative(narrativeDefault)
      }
      updateImageNames(intactImagesPaths)
      updateImages(claimImagesList)
    }
    setLoading(false)
  }, [userTenantId])

  useEffect(() => {
    if (userTenantId) {
      updateStateFromService()
    }
  }, [userTenantId])

  useEffect(() => {
    if (isUserCaas && searchParam.get('clinician') == 'javalin') {
      setUserTenantId(localStorage.getItem('caas_tenant') || '""')
  } else {
      setUserTenantId(authContext.getTenantId(authContext.cognitoAuth))
  }
  }, [authContext])

  return (
    <div id="image-carousel">
      {loadCarousel()}
      {/* Zoom-in click when expanding main image in carousel, only available on if condition that images has more than 0 images */}
      {/* Dynamic size based on original size of img. Height/width is added due to header to give background to the img. */}
      {images.length > 0 && (
        <Modal
          size="xl"
          className="modal-dialog"
          isOpen={zoomOpen}
          toggle={toggleZoomin}
        >
          {/* ModalHeader is required with a toggle function for the 'x' to appear on UI */}
          <ModalHeader
            className="modal-header-removal"
            toggle={toggleZoomin}
          ></ModalHeader>
          <ModalBody>
            <div
              className="enhanced-container"
              style={{ overflow: 'hidden' }}
              ref={myimage}
            >
              <div style={{ position: 'relative' }}>
                <div
                  data-qa={'div-enhanced-image'}
                  className="enhanced-image"
                  ref={ref}
                  onMouseMove={onMouseMove}
                  onMouseDown={() => setClicked(true)}
                  onMouseUp={() => setClicked(false)}
                  style={{
                    backgroundImage:
                      'url(' +
                      `data:image/jpg;image/png;base64,${images[activeIndex].content}` +
                      ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: position.x,
                    top: position.y,
                    transform: `rotate(${rotation}deg) scale(${scale})`,
                    filter: `brightness(${brightness}%) contrast(${contrast}%)`,
                  }}
                />
              </div>
              <div className="enhanced-panel">
                <Button
                  data-qa={'button-rotate-left'}
                  className="rotate-btn"
                  onClick={rotateLeft}
                  sx={{
                    textTransform: 'none',
                    padding: '10px 0 0 0',
                  }}
                  variant="contained"
                >
                  {' '}
                  <div style={{ color: '#fff' }}>
                    {' '}
                    <MdOutlineRotateLeft size="36px" />{' '}
                    <p style={{ fontSize: '12px' }}>Rotate Left</p>{' '}
                  </div>
                </Button>
                <Button
                  data-qa={'button-rotate-right'}
                  className="rotate-btn"
                  onClick={rotateRight}
                  sx={{
                    textTransform: 'none',
                    padding: '10px 0 0 0',
                  }}
                  variant="contained"
                >
                  {' '}
                  <div style={{ color: '#fff' }}>
                    <MdOutlineRotateRight size="36px" />{' '}
                    <p style={{ fontSize: '12px' }}>Rotate Right</p>{' '}
                  </div>
                </Button>

                <div style={{ marginTop: '40px' }}>
                  <div className="slider-label slider-label-header">Zoom</div>
                  <StyledSlider
                    data-qa={'slider-zoom'}
                    value={scale}
                    min={0}
                    max={2}
                    step={0.001}
                    valueLabelDisplay="on"
                    onChange={handleScale}
                    valueLabelFormat={() => {
                      return Math.round(scale * 100) + '%'
                    }}
                  />
                  <div className="slider-label slider-label-right">200%</div>
                  <div className="slider-label">0%</div>

                  <div className="slider-label slider-label-header">
                    Brightness
                  </div>
                  <StyledSlider
                    data-qa={'slider-brightness'}
                    min={0}
                    max={200}
                    value={brightness}
                    valueLabelDisplay="on"
                    onChange={handleBrightness}
                    valueLabelFormat={() => {
                      return Math.round(brightness) + '%'
                    }}
                  />
                  <div className="slider-label slider-label-right">200%</div>
                  <div className="slider-label">0%</div>

                  <div className="slider-label slider-label-header">
                    Contrast
                  </div>
                  <StyledSlider
                    data-qa={'slider-contrast'}
                    min={0}
                    max={200}
                    value={contrast}
                    valueLabelDisplay="on"
                    onChange={handleContrast}
                    valueLabelFormat={() => {
                      return Math.round(contrast) + '%'
                    }}
                  />
                  <div className="slider-label slider-label-right">200%</div>
                  <div className="slider-label">0%</div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="enhance-thumbnail-bar">
            <ol className="enhance-thumbnail-indicators carousel-thumbnail-scroll">
              {/* Dynamic background for thumbnail on carousel indicators, need in-line styling to override bootstrap */}
              {images.map((item, index) => {
                return createThumbnail(item, index)
              })}
            </ol>
          </ModalFooter>
        </Modal>
      )}
      <ErrorSnackbar
        errorMessage={errorSnackbarProps.errorMessage}
        title={errorSnackbarProps.title}
        isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
        handleCloseSnackbar={handleErrorSnackbarClose}
      ></ErrorSnackbar>
    </div>
  )
}

export default ClaimDetailsImageGallery
