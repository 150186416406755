import { IColorChart } from './IColorChart'
import './ColorChart.scss'

export const ColorChart = (props: { colorChartProps: IColorChart }) => {
  const formattedLabel = props.colorChartProps.label
    .replace(/\s+/g, '-')
    .toLowerCase()

  return (
    <div
      className="color-chart-container"
      data-qa={`color-chart-container`}
    >
      <div className="color-bullet" data-qa={`color-chart-${formattedLabel}`} style={{ backgroundColor: props.colorChartProps.color }}></div>
      <span className="label">{props.colorChartProps.label}</span>
    </div>
  )
}
