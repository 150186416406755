import { AxiosError } from 'axios'
import { GetClaimResponse as GetClaimResponse } from '../models/api/GetClaimResponse'
import { ClaimComment } from '../models/ClaimComment'
import { ClaimCommentView } from '../models/ClaimCommentView'
import { ClaimImage } from '../models/ClaimImage'
import { GetClaimsResponse } from '../models/api/GetClaimsResponse'
import { ClaimReview } from '../models/ClaimReview'
import { ClaimsFilter } from '../models/ClaimsFilter'
import { PostClaimCommentResponse } from '../models/api/PostClaimCommentResponse'
import api from './config/axiosConfig'
import { IApiResponse } from '../models/api/IApiResponse'
import { GetImageNamesResponse } from '../models/api/GetImageNamesResponse'
import { GetImageResponse } from '../models/api/GetImageResponse'
import { GetClaimReviewResponse } from '../models/api/GetClaimReviewResponse'
import { GetClaimCommentsResponse } from '../models/api/GetClaimCommentsResponse'
import { PostClinicalAgreementResponse } from '../models/api/PostClinicalAgreementResponse'
import { AxiosErrorResponse } from '../models/api/AxiosErrorResponse'
import { GetClaimHistoryResponse } from '../models/api/GetClaimHistoryResponse'

export interface IClaimsApi {
  getClaims: (claimsFilter: ClaimsFilter) => Promise<GetClaimsResponse>
  getClaim: (tenantId: string, claimNumber: string) => Promise<GetClaimResponse>
  getClaimHistory: (
    tenantId: string,
    claimNumber: string,
    token: string
  ) => Promise<GetClaimHistoryResponse>
  getImageNames: (
    tenantId: string,
    claimNumber: string
  ) => Promise<GetImageNamesResponse>
  getImage: (
    tenantId: string,
    claimNumber: string,
    imageCategory: string,
    imageName: string
  ) => Promise<GetImageResponse>
  getClaimReview: (
    tenantId: string,
    claimNumber: string
  ) => Promise<GetClaimReviewResponse>
  getClaimComments: (
    tenantId: string,
    claimNumber: string
  ) => Promise<GetClaimCommentsResponse>
  postClaimComment: (comment: ClaimComment) => Promise<PostClaimCommentResponse>
  postClinicalAgreement: (
    tenantId: string,
    claimLineId: string,
    clinicalAgreement: string
  ) => Promise<PostClinicalAgreementResponse>
}

export const ClaimsApi: IClaimsApi = {
  getClaims: async (claimsFilter: ClaimsFilter): Promise<GetClaimsResponse> => {
    try {
      const response = await api.get('/claims/', {
        params: {
          tenantId: claimsFilter.tenantId,
          SubmitDateFrom: claimsFilter.submitDateFrom,
          SubmitDateTo: claimsFilter.submitDateTo,
          PageNumber: claimsFilter.pageNumber,
          PageSize: claimsFilter.pageSize,
          SortOrder: 'DESC',
          ...(claimsFilter.claimNumber && claimsFilter.claimNumber !== '%20'
            ? { ClaimNumber: claimsFilter.claimNumber }
            : {}),
          ...(claimsFilter.groupName && claimsFilter.groupName !== '%20'
            ? { GroupName: claimsFilter.groupName }
            : {}),
          ...(claimsFilter.renderingProvider &&
          claimsFilter.renderingProvider !== '%20'
            ? { RenderingProvider: claimsFilter.renderingProvider }
            : {}),
          ...(claimsFilter.sortField && claimsFilter.sortField !== '%20'
            ? { SortField: claimsFilter.sortField }
            : {}),
          ...(claimsFilter.status && claimsFilter.status !== '%20'
            ? { ProcessingStatus: claimsFilter.status }
            : {}),
          ...(claimsFilter.searchTerm && claimsFilter.searchTerm !== '%20'
            ? { Search: claimsFilter.searchTerm }
            : {}),
          ...(claimsFilter.userName && claimsFilter.userName !== ''
            ? { UserName: claimsFilter.userName }
            : {}),
        },
      })
      return {
        ...response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ...new GetClaimsResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  getClaim: async (
    tenantId: string,
    claimNumber: string
  ): Promise<GetClaimResponse> => {
    try {
      const response = await api.get(`/claims/${claimNumber}`, {
        params: {
          tenantId: tenantId,
        },
      })
      return {
        ...response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ...new GetClaimResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  getClaimHistory: async (
    tenantId: string,
    claimNumber: string
  ): Promise<GetClaimHistoryResponse> => {
    try {
      const response = await api.get(`/claims/${claimNumber}/history`, {
        params: {
          tenantId: tenantId,
        },
      })
      return {
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
        claimHistory: response.data,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ...new GetClaimHistoryResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  getImageNames: async (
    tenantId: string,
    claimNumber: string
  ): Promise<GetImageNamesResponse> => {
    try {
      const response = await api.get(`/claims/${claimNumber}/images`, {
        params: {
          tenantId: tenantId,
          imageCategory: 'All',
        },
      })
      return {
        imageNamesList: response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ...new GetImageNamesResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  getImage: async (
    tenantId: string,
    claimNumber: string,
    imageCategory: string,
    imageName: string
  ): Promise<GetImageResponse> => {
    try {
      const response = await api.get(`/claims/${claimNumber}/image`, {
        params: {
          tenantId: tenantId,
          imageCategory: imageCategory,
          imageName: imageName,
        },
      })
      return {
        ...response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      return {
        ...new ClaimImage(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  getClaimReview: async (
    tenantId: string,
    claimNumber: string
  ): Promise<GetClaimReviewResponse> => {
    try {
      const response = await api.get(`/claims/${claimNumber}/review`, {
        params: {
          tenantId: tenantId,
        },
      })
      return {
        claimReviews: response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        claimReviews: Array<ClaimReview>(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  getClaimComments: async (
    tenantId: string,
    claimNumber: string
  ): Promise<GetClaimCommentsResponse> => {
    try {
      const response = await api.get(`/claims/${claimNumber}/comments`, {
        params: {
          tenantId: tenantId,
        },
      })
      return {
        claimComments: response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        claimComments: Array<ClaimCommentView>(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  postClaimComment: async (
    comment: ClaimComment
  ): Promise<PostClaimCommentResponse> => {
    try {
      const response = await api.post(
        `/claims/comment`,
        JSON.stringify(comment)
      )
      return new PostClaimCommentResponse(response.statusText, response.status)
    } catch (error: unknown) {
      console.log(error)
      return {
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },

  postClinicalAgreement: async (
    tenantId: string,
    claimLineId: string,
    clinicalAgreement: string
  ): Promise<PostClinicalAgreementResponse> => {
    try {
      const response = await api.post(
        `/claims/clinicalAgreement`,
        JSON.stringify({
          tenantId: tenantId,
          claimlineId: claimLineId,
          clinicalAgreement: clinicalAgreement,
        })
      )
      return new PostClinicalAgreementResponse(
        response.statusText,
        response.status
      )
    } catch (error) {
      console.log(error)
      return {
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },
}

function getIApiResponseFromError(
  error: AxiosError<AxiosErrorResponse, any>
): IApiResponse {
  if (error.response) {
    const finalErrorMessage =
      error.response.data.message ??
      error.message ??
      'Unknown Error Encountered'
    return {
      apiRequestStatusText: finalErrorMessage,
      apiRequestStatusNumber: error.response.status,
    }
  }
  if (error.message) {
    // hack to solve bug 130239 since no better solution has been found yet
    if (error.message === 'Network Error')
      return {
        apiRequestStatusText:
          'Cannot complete the request. Try logging out and back in.',
        apiRequestStatusNumber: 401,
      }
    else {
      return {
        apiRequestStatusText: error.message,
      }
    }
  }
  return {
    apiRequestStatusText: 'Unknown Error Encountered',
  }
}
