import { useEffect, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider'
import '../../sass/components/details/_details-review.scss'
import { ClinicalAgreementOptions } from '../../types/enums'
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Dialog,
  FormControlLabel,
  Box,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material'
import tenantConfig from '../../tenantConfig.json'
import { ClaimsApi } from '../../api/ClaimsApi'
import ErrorSnackbar from '../ErrorSnackbar'
import React from 'react'
import { CaasApi } from '../../api/CaasApi'

interface InputPropsWithData
  extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-qa'?: string | undefined
}
declare module '@mui/material/Radio' {
  interface RadioProps {
    inputProps?: InputPropsWithData
  }
}

export const ClaimDetailsClinicianFeedback = (props: {
  isCaasAdjudicationDisplayed: boolean
  isClaimEditable: boolean
  isClaimCompleted: boolean
  clinicalAgreement: string
  caasAdjudication: string
  claimLineId: number
  recommendation: string
  caasAdjudicationDowncode: string
  caasAdjudicationInformation: string
  tenantAcronym: string
}) => {
  const authContext = useContext(AuthContext)
  const [isUserCaas, setUserCass] = useState(
    authContext.isUserCaas(authContext.cognitoAuth)
  )
  const navigate = useNavigate()
  const [agreement, setAgreement] = useState(props.clinicalAgreement)
  const [adjudication, setAdjudication] = useState(props.caasAdjudication)
  const [caasAdjudicationDowncodeValue, setCaasAdjudicationDowncodeValue] = useState(props.caasAdjudicationDowncode)
  const [partialApprovalOtherInformation, setPartialApprovalOtherInformation] =
    useState(
      !new Array('D4342', 'D7140', 'D7210', 'D7220', 'D7230').includes(
        props.caasAdjudicationDowncode
      )
        ? props.caasAdjudicationDowncode
        : ''
    )
  const [caasAdjudicationInformationValue, setCaasAdjudicationInformationValue] = useState(props.caasAdjudicationInformation)
  const [
    partialApprovalAdditionalInformation,
    setPartialApprovalAdditionalInformation,
  ] = useState(
    adjudication == 'PartialApproval'
      ? caasAdjudicationInformationValue?.substring(
        caasAdjudicationInformationValue.indexOf('AdditionalInformation:') +
            22
        )
      : ''
  )
  const [requestAdditionalInformation, setRequestAdditionalInformation] = useState(
    adjudication == 'RequestAdditionalInformation'
      ? caasAdjudicationInformationValue?.substring(22)
      : ''
  )
  const [userTenantId, setUserTenantId] = useState('')
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unknown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
  const [openRequestAdditionalInfo, setOpenRequestAdditionalInfo] =
    React.useState(false)
  const [openPartialApproval, setOpenPartialApproval] = React.useState(false)
  const [partialApprovalReason, setPartialApprovalReason] = useState(
    caasAdjudicationInformationValue?.substring(
      8,
      caasAdjudicationInformationValue?.indexOf('AdditionalInformation:') - 2
    ) == '' || caasAdjudicationInformationValue?.substring(0, 8) !== 'Reason: '
      ? 'None'
      : caasAdjudicationInformationValue?.substring(
          8,
          caasAdjudicationInformationValue?.indexOf('AdditionalInformation:') -
            2
        )
  )

  const tenantConfigIndex: number = tenantConfig.tenants.findIndex(
    (tenant) => tenant.id === props.tenantAcronym
  )
  const caasDenialReferenceCodes: string[] =
    tenantConfig.tenants[tenantConfigIndex].CaasDenialReferenceCodes

  let shouldOpenDenyPopUp = false
  if (caasDenialReferenceCodes.length > 0) {
    shouldOpenDenyPopUp = true
  }
  const [openDenyPopUp, setOpenDenyPopUp] = React.useState(false)

  const caasAdjudicationInformation =
    props.caasAdjudicationInformation?.substring('DenyReason: '.length)

  const [denyReferenceCode, setDenyReferenceCode] = useState(
    adjudication === 'Deny'
      ? caasDenialReferenceCodes.includes(caasAdjudicationInformation)
        ? caasAdjudicationInformation
        : 'Other'
      : ''
  )

  const [denyReferenceCodeValue, setDenyReferenceCodeValue] = useState(
    denyReferenceCode
  )

  const [denyReferenceCodeOtherText, setDenyReferenceCodeOtherText] = useState(
    denyReferenceCode === 'Other' ? props.caasAdjudicationInformation?.substring('DenyReason: '.length) : ''
  )

  const [partialApprovalAppropriateCode, setPartialApprovalAppropriateCode] =
    useState(
      new Array('D4342', 'D7140', 'D7210', 'D7220', 'D7230').includes(
        props.caasAdjudicationDowncode
      )
        ? caasAdjudicationDowncodeValue
        : caasAdjudicationDowncodeValue == '' ||
          caasAdjudicationDowncodeValue == null
        ? ''
        : 'Other'
    )
  const { search } = useLocation()
  const searchParam = React.useMemo(() => new URLSearchParams(search), [search])

  const [errorFlagPartialApproval, setErrorFlagPartialApproval] =
    useState(false)
  const [helperTextPartialApproval, setHelperTextPartialApproval] = useState('')

  const [errorFlagAppropriateCode, setErrorFlagAppropriateCode] =
    useState(false)
  const [helperTextAppropriateCode, setHelperTextAppropriateCode] = useState('')

  const [
    errorFlagOtherInformationPartialApproval,
    setErrorFlagOtherInformationPartialApproval,
  ] = useState(false)
  const [
    helperTextOtherInformationPartialApproval,
    setHelperTextOtherInformationPartialApproval,
  ] = useState('')

  const [errorFlagOtherInformationDeny, setErrorFlagOtherInformationDeny] =
    useState(false)
  const [helperTextOtherInformationDeny, setHelperTextOtherInformationDeny] =
    useState('')

  const [errorDenyReferenceCode, setErrorDenyReferenceCode] = useState(false)
  const [helperTextDenyReferenceCode, setHelperTextDenyReferenceCode] =
    useState('')

  const [errorFlagAdditionalInformation, setErrorFlagAdditionalInformation] =
    useState(false)
  const [helperTextAdditionalInformation, setHelperTextAdditionalInformation] =
    useState('')

  const onChangePartialApprovalReason = (e: any) => {
    setPartialApprovalReason(e.target.value)
  }

  const onChangePartialAppropriateCode = (e: any) => {
    setPartialApprovalAppropriateCode(e.target.value)
  }

  const onChangePartialOtherInformation = (e: any) => {
    setPartialApprovalOtherInformation(e.target.value)
  }

  const onChangePartialAdditionalInformation = (e: any) => {
    setPartialApprovalAdditionalInformation(e.target.value)
  }

  const onChangeRequestAdditionalInformation = (e: any) => {
    setRequestAdditionalInformation(e.target.value)
  }

  const onChangeDenyReferenceCode = (e: any) => {
    setDenyReferenceCode(e.target.value)
  }

  const onChangeDenyOtherInformation = (e: any) => {
    setDenyReferenceCodeOtherText(e.target.value)
  }

  const handleClickOpenRequestAdditionalInfo = () => {
    setOpenRequestAdditionalInfo(true)
  }

  const handleClickOpenPartialApproval = () => {
    setOpenPartialApproval(true)
  }

  const handleClickOpenDenyPopUp = () => {
    setOpenDenyPopUp(true)
  }

  const handleCloseRequestAdditionalInfo = (resetValue: boolean) => {
    if(resetValue){
      setRequestAdditionalInformation(
        adjudication == 'RequestAdditionalInformation'
          ? caasAdjudicationInformationValue?.substring(22)
          : ''
      );
    }
    setOpenRequestAdditionalInfo(false)
  }

  const handleClosePartialApproval = (resetValue: boolean) => {
    if(resetValue){
    setPartialApprovalOtherInformation(
      !new Array('D4342', 'D7140', 'D7210', 'D7220', 'D7230').includes(
        caasAdjudicationDowncodeValue
      )
        ? caasAdjudicationDowncodeValue
        : ''
    );
    setPartialApprovalAdditionalInformation(
      adjudication == 'PartialApproval'
        ? caasAdjudicationInformationValue?.substring(
          caasAdjudicationInformationValue.indexOf('AdditionalInformation:') +
              22
          )
        : ''
    );
    setPartialApprovalReason(
      caasAdjudicationInformationValue?.substring(
        8,
        caasAdjudicationInformationValue?.indexOf('AdditionalInformation:') - 2
      ) == '' || caasAdjudicationInformationValue?.substring(0, 8) !== 'Reason: '
        ? 'None'
        : caasAdjudicationInformationValue?.substring(
            8,
            caasAdjudicationInformationValue?.indexOf('AdditionalInformation:') -
              2
          )
    );
    setPartialApprovalAppropriateCode(
      new Array('D4342', 'D7140', 'D7210', 'D7220', 'D7230').includes(
        caasAdjudicationDowncodeValue
      )
        ? caasAdjudicationDowncodeValue
        : caasAdjudicationDowncodeValue == '' ||
        caasAdjudicationDowncodeValue == null
        ? ''
        : 'Other'
    );
  }
    setErrorFlagOtherInformationPartialApproval(false)
    setHelperTextOtherInformationPartialApproval('')
    setErrorFlagPartialApproval(false)
    setHelperTextPartialApproval('')
    setErrorFlagAppropriateCode(false)
    setHelperTextAppropriateCode('')
    setOpenPartialApproval(false)
  }

  const handleCloseDenyPopUp = (resetValue: boolean) => {
    if(resetValue)
      {
        setDenyReferenceCode(denyReferenceCodeValue)        
        setDenyReferenceCodeOtherText(denyReferenceCodeValue === 'Other' ? caasAdjudicationInformationValue.substring("DenyReason: ".length) : '')
      }
    setErrorFlagOtherInformationDeny(false)
    setHelperTextOtherInformationDeny('')
    setErrorDenyReferenceCode(false)
    setHelperTextDenyReferenceCode('')
    setOpenDenyPopUp(false)
  }

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }
  useEffect(() => {
    if (isUserCaas && searchParam.get('clinician') == 'javalin') {
      setUserTenantId(localStorage.getItem('caas_tenant') || '""')
    } else {
      setUserTenantId(authContext.getTenantId(authContext.cognitoAuth))
    }
  }, [authContext])

  const onChange = async (event: SelectChangeEvent) => {
    let postResponse = null

    if (isUserCaas && searchParam.get('clinician') == 'javalin') {
      if (event.target.value === 'Deny' && !shouldOpenDenyPopUp) {
        setAdjudication(event.target.value)
        postResponse = await CaasApi.postCAASAdjudication(
          userTenantId,
          props.claimLineId.toString(),
          event.target.value,
          '',
          ''
        )
      }
      
      if(event.target.value === "Approve")
        {
          setDenyReferenceCode('')
          setDenyReferenceCodeValue("")
          setDenyReferenceCodeOtherText("")
          setCaasAdjudicationDowncodeValue('')
          setPartialApprovalAppropriateCode('')
          setRequestAdditionalInformation('')
          setPartialApprovalReason('None')
          setPartialApprovalOtherInformation('')
          setPartialApprovalAdditionalInformation('')
          setCaasAdjudicationInformationValue('')
        }

      if (
        event.target.value !== 'PartialApproval' &&
        event.target.value !== 'Deny' &&
        event.target.value !== 'RequestAdditionalInformation'
      ) {
        setAdjudication(event.target.value)
        postResponse = await CaasApi.postCAASAdjudication(
          userTenantId,
          props.claimLineId.toString(),
          event.target.value,
          '',
          ''
        )
      }
    } else {
      setAgreement(event.target.value)
      postResponse = await ClaimsApi.postClinicalAgreement(
        userTenantId,
        props.claimLineId.toString(),
        event.target.value
      )
    }

    if (postResponse !== null) HandleApiCall(postResponse, event)
  }

  const HandleApiCall = (postResponse: any, event: any) => {
    if (
      !postResponse.apiRequestStatusNumber ||
      String(postResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      if (postResponse.apiRequestStatusNumber === 401) {
        localStorage.setItem(
          'requestedUrl',
          window.location.pathname + window.location.search
        )
        navigate('/login', { replace: true })
      } else {
        console.error(postResponse)
        setErrorSnackbarProps({
          errorMessage: postResponse.apiRequestStatusText,
          isOpenErrorSnackbar: true,
          title: `Request status: ${
            postResponse.apiRequestStatusNumber ?? 'unknown'
          }`,
        })
      }
      return
    }
  }

  const RenderRequestAdditionalInformationPopup = () => {
    return (
      <Dialog
        onChange={onChangeRequestAdditionalInformation}
        open={openRequestAdditionalInfo}
        onClose={handleCloseRequestAdditionalInfo}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            setErrorFlagAdditionalInformation(false)
            setHelperTextAdditionalInformation('')

            event.preventDefault()
            if (requestAdditionalInformation !== '') {
              setAdjudication('RequestAdditionalInformation')
              setCaasAdjudicationInformationValue('AdditionalInformation: ' + requestAdditionalInformation)
              setCaasAdjudicationDowncodeValue('')
              setDenyReferenceCodeOtherText('')
              setDenyReferenceCode('')
              setDenyReferenceCodeValue('')
              let postResponse = await CaasApi.postCAASAdjudication(
                userTenantId,
                props.claimLineId.toString(),
                'RequestAdditionalInformation',
                'AdditionalInformation: ' + requestAdditionalInformation,
                ''
              )
              HandleApiCall(postResponse, event)
              handleCloseRequestAdditionalInfo(false)
            } else {
              setErrorFlagAdditionalInformation(true)
              setHelperTextAdditionalInformation(
                'The additional information section must be filled'
              )
            }
          },
        }}
      >
        <DialogTitle style={{ fontSize: '18px', fontWeight: '700' }}>
          Additional Information
        </DialogTitle>
        <DialogContent style={{ width: 450, height: 160 }}>
          <TextField
            margin="dense"
            name="AdditionalInfo"
            label="Additional Information"
            type="text"
            fullWidth
            error={errorFlagAdditionalInformation}
            helperText={helperTextAdditionalInformation}
            multiline
            variant="filled"
            rows={4}
            value={requestAdditionalInformation}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: '700' }}
            variant="contained"
            onClick={() => handleCloseRequestAdditionalInfo(true)}
            data-qa="cancel-0"
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="submit"
            sx={{ fontWeight: '700' }}
            variant="contained"
            data-qa="save-0"
          >
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const RenderPartialApprovalPopup = () => {
    return (
      <Dialog
        open={openPartialApproval}
        onClose={() => handleClosePartialApproval(false)}
        PaperProps={{
          component: 'form',
          sx: { maxWidth: 'revert', width: 650 },
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            let validForm = true
            setErrorFlagOtherInformationPartialApproval(false)
            setHelperTextOtherInformationPartialApproval('')
            setErrorFlagPartialApproval(false)
            setHelperTextPartialApproval('')
            setErrorFlagAppropriateCode(false)
            setHelperTextAppropriateCode('')
            if (
              partialApprovalAppropriateCode === 'Other' &&
              (partialApprovalOtherInformation === null ||
              partialApprovalOtherInformation.length === 0)
            ) {
              validForm = false
              setErrorFlagOtherInformationPartialApproval(true)
              setHelperTextOtherInformationPartialApproval(
                'The other section must be filled'
              )
            }

            if (
              partialApprovalAppropriateCode !== 'Other' &&
              partialApprovalReason === 'None'
            ) {
              validForm = false
              setErrorFlagPartialApproval(true)
              setHelperTextPartialApproval('A reason needs to be set')
            }

            if (
              partialApprovalAppropriateCode !== 'Other' &&
              typeof partialApprovalAppropriateCode === 'string' &&
              partialApprovalAppropriateCode.length === 0
            ) {
              validForm = false
              setErrorFlagAppropriateCode(true)
              setHelperTextAppropriateCode(
                'One appropriate code has to be selected'
              )
            }

            if (validForm) {
              setAdjudication('PartialApproval')
              setRequestAdditionalInformation('')
              setDenyReferenceCodeOtherText('')
              setDenyReferenceCode('')
              setDenyReferenceCodeValue('')
              setCaasAdjudicationInformationValue('Reason: ' +
                  partialApprovalReason +
                  ', AdditionalInformation:' +
                  partialApprovalAdditionalInformation)
              setCaasAdjudicationDowncodeValue(partialApprovalAppropriateCode === 'Other'
              ? partialApprovalOtherInformation
              : partialApprovalAppropriateCode)
              let postResponse = await CaasApi.postCAASAdjudication(
                userTenantId,
                props.claimLineId.toString(),
                'PartialApproval',
                'Reason: ' +
                  partialApprovalReason +
                  ', AdditionalInformation:' +
                  partialApprovalAdditionalInformation,
                partialApprovalAppropriateCode === 'Other'
                  ? partialApprovalOtherInformation
                  : partialApprovalAppropriateCode
              )
              HandleApiCall(postResponse, event)
              handleClosePartialApproval(false)
            }
          },
        }}
      >
        <DialogContent>
          <Box
            component="div"
            fontSize={18}
            fontWeight={700}
            data-qa="partial-approval-reason-div"
          >
            Partial Approval Reason:
          </Box>
          {PartialApprovalEdit()}
          <Box
            component="div"
            fontSize={18}
            fontWeight={700}
            data-qa="appropriate-code-div"
          >
            Appropriate Code:
          </Box>
          {AppropriateCodeSectionRadio()}
          <TextField
            hidden={partialApprovalAppropriateCode !== 'Other'}
            margin="dense"
            name="PartialApprovalOtherInfo"
            type="text"
            fullWidth
            error={errorFlagOtherInformationPartialApproval}
            helperText={helperTextOtherInformationPartialApproval}
            multiline
            variant="standard"
            value={partialApprovalOtherInformation}
            onChange={onChangePartialOtherInformation}
          />
          <TextField
            margin="dense"
            name="AdditionalInfo"
            label="Additional Information"
            type="text"
            fullWidth
            multiline
            variant="filled"
            rows={4}
            value={partialApprovalAdditionalInformation}
            onChange={onChangePartialAdditionalInformation}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: '700' }}
            variant="contained"
            onClick={() => handleClosePartialApproval(true)}
            data-qa="cancel-0"
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="submit"
            sx={{ fontWeight: '700' }}
            variant="contained"
            data-qa="save-0"
          >
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const RenderDenyPopup = () => {
    return (
      <Dialog
        open={openDenyPopUp}
        onClose={() => handleCloseDenyPopUp(true)}
        PaperProps={{
          component: 'form',
          sx: { maxWidth: 'revert', width: 650 },
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            let validForm = true  
            setErrorFlagOtherInformationDeny(false)
            setHelperTextOtherInformationDeny('')
            setErrorDenyReferenceCode(false)
            setHelperTextDenyReferenceCode('')
            
            if (
              denyReferenceCode === 'Other' &&
              typeof denyReferenceCodeOtherText === 'string' &&
              denyReferenceCodeOtherText.length === 0
            ) {
              validForm = false
              setErrorFlagOtherInformationDeny(true)
              setHelperTextOtherInformationDeny(
                'The other section must be filled'
              )
            }

            if (
              denyReferenceCode !== 'Other' &&
              typeof denyReferenceCode === 'string' &&
              denyReferenceCode.length === 0
            ) {
              validForm = false
              setErrorDenyReferenceCode(true)
              setHelperTextDenyReferenceCode(
                'One appropriate code has to be selected'
              )
            }

            if (validForm) {
              setDenyReferenceCodeValue(denyReferenceCode)
              setAdjudication('Deny')
              setCaasAdjudicationInformationValue('DenyReason: ' +(denyReferenceCode === "Other" ? denyReferenceCodeOtherText : denyReferenceCode))
              if(denyReferenceCode !== "Other")
                setDenyReferenceCodeOtherText("")
              setCaasAdjudicationDowncodeValue('')
              setPartialApprovalAppropriateCode('')
              setRequestAdditionalInformation('')
              setPartialApprovalReason('None')
              setPartialApprovalOtherInformation('')
              setPartialApprovalAdditionalInformation('')
              let postResponse = await CaasApi.postCAASAdjudication(
                userTenantId,
                props.claimLineId.toString(),
                'Deny',
                'DenyReason: ' +(denyReferenceCode === "Other" ? denyReferenceCodeOtherText : denyReferenceCode),
                ''
              )
              HandleApiCall(postResponse, event)
              handleCloseDenyPopUp(false)
            }
          },
        }}
      >
        <DialogContent>
          <Box
            component="div"
            fontSize={18}
            fontWeight={700}
            data-qa="denial-popup-title"
          >
            Reason for denial:
          </Box>
          {DenyReferenceCodeSectionRadio()}
          <TextField
            hidden={denyReferenceCode !== 'Other'}
            margin="dense"
            name="DenyOtherInfo"
            type="text"
            fullWidth
            defaultValue={denyReferenceCodeOtherText}
            error={errorFlagOtherInformationDeny}
            helperText={helperTextOtherInformationDeny}
            multiline
            variant="standard"
            inputProps={{
              'data-qa': 'denial-popup-other-text',
            }}
            onChange={onChangeDenyOtherInformation}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: '700' }}
            variant="contained"
            onClick={() => handleCloseDenyPopUp(true)}
            data-qa="cancel-0"
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="submit"
            sx={{ fontWeight: '700' }}
            variant="contained"
            data-qa="save-0"
          >
            <span>Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  let selectedClassAgreement = ''
  switch (agreement) {
    case ClinicalAgreementOptions.AGREED:
      selectedClassAgreement = 'agreed'
      break
    case ClinicalAgreementOptions.DISAGREED:
      selectedClassAgreement = 'disagreed'
      break
    case ClinicalAgreementOptions.INSUFFICIENTDATA:
      selectedClassAgreement = 'insufficient-data'
      break
    default:
      selectedClassAgreement = ''
      break
  }

  let selectedClassAdjudication = ''
  switch (adjudication) {
    case ClinicalAgreementOptions.APPROVE:
      selectedClassAdjudication = 'agreed'
      break
    case ClinicalAgreementOptions.DENY:
      selectedClassAdjudication = 'disagreed'
      break
    case ClinicalAgreementOptions.PARTIALAPPROVAL:
      selectedClassAdjudication = 'partial-approval'
      break
    case ClinicalAgreementOptions.REQUESTADDITIONALINFORMATION:
      selectedClassAdjudication = 'more-information'
      break
    default:
      selectedClassAdjudication = ''
      break
  }

  const AgreementOptionsEditTenant = () => {
    return (
      <div className={`select-container ${selectedClassAgreement}`}>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            borderRadius: '50px',
            marginLeft: '10px',
            marginTop: 0,
            marginBottom: 0,
            marginRight: 0,
          }}
          size="small"
        >
          <Select
            onChange={onChange}
            value={agreement}
            sx={{ borderRadius: '50px' }}
          >
            <MenuItem value={ClinicalAgreementOptions.NONE}>Select</MenuItem>
            <MenuItem value={ClinicalAgreementOptions.AGREED}>Agreed</MenuItem>
            <MenuItem value={ClinicalAgreementOptions.DISAGREED}>
              Disagreed
            </MenuItem>
            <MenuItem value={ClinicalAgreementOptions.INSUFFICIENTDATA}>
              Insufficient Data
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    )
  }

  const AgreementOptionsEditAdmin = (isClaimEditable: boolean) => {
    return (
      <div className={`select-container ${selectedClassAdjudication}`}>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            borderRadius: '50px',
            marginLeft: '10px',
            marginTop: 0,
            marginBottom: 0,
            marginRight: 0,
          }}
          size="small"
        >
          <Select
            onChange={onChange}
            readOnly={!isClaimEditable}
            hidden={!(props.isCaasAdjudicationDisplayed || isClaimEditable)}
            value={adjudication}
            sx={{ borderRadius: '50px' }}
          >
            <MenuItem value={ClinicalAgreementOptions.NONE}>Select</MenuItem>
            <MenuItem value={ClinicalAgreementOptions.APPROVE}>
              Approve
            </MenuItem>
            <MenuItem
              value={ClinicalAgreementOptions.DENY}
              onClick={() =>
                shouldOpenDenyPopUp ? handleClickOpenDenyPopUp() : null
              }
            >
              Deny
            </MenuItem>
            <MenuItem
              value={ClinicalAgreementOptions.PARTIALAPPROVAL}
              onClick={() => handleClickOpenPartialApproval()}
            >
              Partial Approval
            </MenuItem>
            <MenuItem
              value={ClinicalAgreementOptions.REQUESTADDITIONALINFORMATION}
              onClick={() => handleClickOpenRequestAdditionalInfo()}
            >
              Request Additional Information
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    )
  }

  const PartialApprovalEdit = () => {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          data-qa="partial-approval-reason-dd"
          onChange={onChangePartialApprovalReason}
          defaultValue="None"
          error={errorFlagPartialApproval}
          value={partialApprovalReason}
        >
          <MenuItem value={'None'}>Select reason</MenuItem>
          <MenuItem value={'No radiographic bone loss detected in quadrant'}>
            No radiographic bone loss detected in quadrant
          </MenuItem>
          <MenuItem
            value={
              'Radiographic bone loss detected in fewer than 4 teeth in quadrant'
            }
          >
            Radiographic bone loss detected in fewer than 4 teeth in quadrant
          </MenuItem>
          <MenuItem
            value={'Does not meet established clinical criteria per contract'}
          >
            Does not meet established clinical criteria per contract
          </MenuItem>
        </Select>
        <FormHelperText
          sx={{ color: 'red' }}
          data-qa="partial-approval-reason-helper"
        >
          {helperTextPartialApproval}
        </FormHelperText>
      </FormControl>
    )
  }

  const AppropriateCodeSectionRadio = () => {
    return (
      <FormControl error={errorFlagAppropriateCode}>
        <FormHelperText data-qa="partial-approval-appropriate-code-helper">
          {helperTextAppropriateCode}
        </FormHelperText>
        <RadioGroup
          onChange={onChangePartialAppropriateCode}
          value={partialApprovalAppropriateCode}
          data-qa="appropriate-code-radio"
        >
          <FormControlLabel value="D4342" control={<Radio />} label="D4342" />
          <FormControlLabel value="D7140" control={<Radio />} label="D7140" />
          <FormControlLabel value="D7210" control={<Radio />} label="D7210" />
          <FormControlLabel value="D7220" control={<Radio />} label="D7220" />
          <FormControlLabel value="D7230" control={<Radio />} label="D7230" />
          <FormControlLabel value="Other" control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
    )
  }

  const DenyReferenceCodeSectionRadio = () => {
    const radioButtons = caasDenialReferenceCodes.map((code, index) => {
      const dataQaId = code
        .replace(/(,|–\s)/g, '')
        .split(' ')
        .slice(0, 4)
        .join('-')
      return (
        <FormControlLabel
          key={code}
          value={code}
          control={
            <Radio
              inputProps={{
                'data-qa': `denial-popup-${dataQaId}-radio-input`,
              }}
            />
          }
          label={code}
          data-qa={`denial-popup-${dataQaId}-radio-label`}
        />
      )
    })
    return (
      <FormControl error={errorDenyReferenceCode}>
        <FormHelperText>{helperTextDenyReferenceCode}</FormHelperText>
        <RadioGroup
          onChange={onChangeDenyReferenceCode}
          defaultValue={denyReferenceCode}
        >
          {radioButtons}
        </RadioGroup>
      </FormControl>
    )
  }

  const GetAgreementOptions = () => {
    if (searchParam.get('clinician') == 'javalin') {
      return AgreementOptionsEditAdmin(props.isClaimEditable)
    }
    if (props.isClaimCompleted && props.recommendation !== 'NoAiReview') {
      return AgreementOptionsEditTenant()
    }
  }

  return (
    <div>
      {RenderPartialApprovalPopup()}
      {RenderRequestAdditionalInformationPopup()}
      {RenderDenyPopup()}
      <span>{GetAgreementOptions()}</span>
      <ErrorSnackbar
        errorMessage={errorSnackbarProps.errorMessage}
        title={errorSnackbarProps.title}
        isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
        handleCloseSnackbar={handleErrorSnackbarClose}
      />
    </div>
  )
}
