import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import ClaimsGrid from './components/ClaimsGrid'
import CaasClaimsGrid from './components/CaasClaimsGrid'
import { config } from 'dotenv'
import path from 'path'
import Header from './components/Header'
import ClaimDetails from './components/ClaimDetails'
import SearchResults from './components/SearchResults'
import { Assign } from './components/Assign'
import Login from './components/Login'
import { AuthProvider } from './auth/AuthProvider'
import { useEffect } from 'react'
import RequireAuth from './components/RequireAuth'
import Callback from './components/Callback'
import { LicenseInfo } from '@mui/x-license-pro'
import { ThemeProvider } from '@mui/material'
import theme from './themes/muiTheme'
import TenantSelection from './components/TenantSelection'

config({
  path: path.resolve(__dirname, `.env.${process.env.NODE_ENV}`),
})

function App() {
  LicenseInfo.setLicenseKey(
    '5bb247db094123e196d8a6bd685571a7Tz04NzQ1MyxFPTE3NDMzMjg5ODQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
  )
  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITLE}`
  }, [])

  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Header />
            <Routes>
              <Route path={'/'} element={<Login />} />
              <Route
                path="/claims"
                element={
                  <RequireAuth>
                    <ClaimsGrid />
                  </RequireAuth>
                }
              />
              <Route
                path="/claims/:claimNumber"
                element={
                  <RequireAuth>
                    <ClaimDetails />
                  </RequireAuth>
                }
              />
              <Route
                path="/search"
                element={
                  <RequireAuth>
                    <SearchResults searchString={searchTerm} />
                  </RequireAuth>
                }
              />
              <Route
                path="/assign"
                element={
                  <RequireAuth>
                    <Assign />
                  </RequireAuth>
                }
              />
              <Route
                path="/caas/claims"
                element={
                  <RequireAuth>
                    <CaasClaimsGrid />
                  </RequireAuth>
                }
              />
              <Route
                path="/caas/tenants"
                element={
                  <RequireAuth>
                    <TenantSelection />
                  </RequireAuth>
                }
              />
              <Route path="/callback" element={<Callback />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
