import React from 'react'
import theme from '../themes/muiTheme'
import {
  BsCaretUp,
  BsCaretUpFill,
  BsCaretDown,
  BsCaretDownFill,
} from 'react-icons/bs'

interface CaasCustomSortIconProps {
  direction: 'asc' | 'desc' | null
}

const style: { icons: React.CSSProperties } = {
  icons: {
    display: 'flex',
    flexDirection: 'column',
    width: '22px',
    height: '22px',
    color: `${theme.palette.primary.main}`,
  },
}

const CaasCustomSortIcon: React.FC<CaasCustomSortIconProps> = ({
  direction,
}) => {
  switch (direction) {
    case 'asc':
      return (
        <div style={style.icons} data-qa="sort-asc-icon">
          <BsCaretUpFill />
          <BsCaretDown />
        </div>
      )
    case 'desc':
      return (
        <div style={style.icons} data-qa="sort-desc-icon">
          <BsCaretUp />
          <BsCaretDownFill />
        </div>
      )
    default:
      return null
  }
}

export default CaasCustomSortIcon
