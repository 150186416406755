import { UserDetails } from '../models/UserDetails'
import IAuthContext from '../types/IAuthContext'

export const setKendoAttribute = (
  componentRef: React.RefObject<any>,
  attr: string,
  value: string
) => {
  if (componentRef && componentRef.current && componentRef.current.element) {
    componentRef.current.element.setAttribute(`${attr}`, `${value}`)
  }
}

export function addDataQaAttributesToPagination(): void {
  const ulElement = document.querySelector('.MuiPagination-ul')
  if (!(ulElement instanceof HTMLUListElement)) return

  const buttons = ulElement.querySelectorAll('li > button')

  buttons.forEach((button) => {
    if (!(button instanceof HTMLButtonElement)) return

    const svgElement = button.querySelector('svg')
    if (svgElement) {
      const ariaLabel = button.getAttribute('aria-label')
      switch (ariaLabel) {
        case 'Go to first page':
          button.setAttribute('data-qa', 'btn-Go-to-first-page')
          break
        case 'Go to last page':
          button.setAttribute('data-qa', 'btn-Go-to-last-page')
          break
        case 'Go to previous page':
          button.setAttribute('data-qa', 'btn-Go-to-previous-page')
          break
        case 'Go to next page':
          button.setAttribute('data-qa', 'btn-Go-to-next-page')
          break
      }
    } else {
      const pageNumber = button.innerText.trim()
      button.setAttribute('data-qa', `btn-page-${pageNumber}`)
    }
  })

  const divSelect = document.querySelector(
    '.MuiSelect-select.MuiTablePagination-select'
  )
  if (divSelect instanceof HTMLDivElement) {
    divSelect.setAttribute('data-qa', 'rows-per-page-selector')
  }

  const divElement = document.querySelector('.Pagination-select')
  if (divElement instanceof HTMLDivElement) {
    divElement.setAttribute('data-qa', 'rows-per-page-container')
  }

  const inputElement = document.querySelector(
    '.MuiSelect-nativeInput:not(.caas-tenant-select-input)'
  )
  if (inputElement instanceof HTMLInputElement) {
    inputElement.setAttribute('data-qa', 'rows-per-page-input')
  }

  const pElement = document.querySelector('.MuiTablePagination-displayedRows')
  if (pElement instanceof HTMLParagraphElement) {
    pElement.setAttribute('data-qa', 'rows-per-page-displayed-rows')
  }
}

export function addDataQaAttributesToCaasHomepage(): void {
  const tenantSelectDiv = document.getElementById('tenant-select-dropdown')
  if (tenantSelectDiv instanceof HTMLDivElement) {
    tenantSelectDiv.setAttribute('data-qa', 'tenant-select-div')
  }

  const tenantSelectInput = document.querySelector('.caas-tenant-select-input')
  if (tenantSelectInput instanceof HTMLInputElement) {
    tenantSelectInput.setAttribute('data-qa', 'tenant-select-input')
  }
}

export function addDataQaAttributesToCaasHomepageTenantDropdown(): void {
  const tenantSelectInput = document.querySelector('ul.MuiMenu-list')
  if (tenantSelectInput instanceof HTMLUListElement) {
    tenantSelectInput.setAttribute('data-qa', 'tenant-select-ul')
  }
}

const getColNameForEl = (index: number) => {
  // attach id to claims grid to locate
  let grid = document.getElementsByClassName('claims-grid')[0]
  grid !== undefined ?? grid.setAttribute('id', 'claims-grid')

  let colLabels = [
    'Claim Number',
    'View Details',
    'Status in Javalin',
    'Submit Date',
    'Rendering Provider',
    'Group Name',
    'Assigned User',
  ]
  if (typeof colLabels[index] === 'string') {
    return colLabels[index].replace(/ /g, '')
  } else {
    return ''
  }
}

export function dataQaAttr(el: HTMLElement | Element, value: string) {
  el.setAttribute('data-qa', value)
}

export const setHtmlAttributes = () => {
  let tr = document.querySelectorAll('tr')
  let td = document.querySelectorAll('td')
  let input = document.querySelectorAll('input')
  let button = document.querySelectorAll('button')
  let dropdown = document.getElementsByClassName('k-dropdown-wrap')
  let option = document.querySelectorAll('option')
  let span = document.querySelectorAll('span')

  type NodeOrHTML = HTMLCollectionOf<Element> | NodeListOf<Element>

  function isHtmlCollection(el: NodeOrHTML): el is HTMLCollectionOf<Element> {
    return (el as HTMLCollectionOf<Element>) ? true : false
  }

  function attachAttr(
    elements: NodeListOf<HTMLElement> | HTMLCollectionOf<Element>,
    value: string
  ): any {
    // ensure HTML collection is converted to array if not NodeList
    let els = isHtmlCollection(elements) ? Array.from(elements) : elements

    els.forEach((el, index) => {
      let tableHead = el.closest('th') as HTMLTableCellElement
      const dropdowns = Array.from(
        document.getElementsByClassName('k-dropdown-wrap')
      )

      Array.prototype.slice.call(el.attributes).forEach(function (item) {
        if (item.name === 'class') {
          switch (el.tagName) {
            case 'INPUT':
              if (el.closest('.k-filtercell-wrapper')) {
                dataQaAttr(el, 'input-' + getColNameForEl(tableHead?.cellIndex))
              }
              break
            case 'BUTTON':
              if (el.getAttribute('title') === 'Clear') {
                dataQaAttr(
                  el,
                  'button-Clear-' + getColNameForEl(tableHead?.cellIndex)
                )
              } else if (el.classList.contains('sign-out-btn')) {
                dataQaAttr(el, 'sign-out')
              }
              break
            case 'SPAN':
              if (
                el.parentNode?.nodeName === 'BUTTON' &&
                item.value.includes('refresh')
              ) {
                dataQaAttr(el, 'refresh-' + 0)
              } else if (
                el.parentNode?.nodeName === 'BUTTON' &&
                item.value.includes('k-i-download')
              ) {
                dataQaAttr(el, 'export-' + 0)
              } else if (el.className === 'k-dropdown-wrap') {
                dropdowns.forEach((item, index) => {
                  el === dropdowns[index]
                    ? dataQaAttr(
                        el,
                        'dropdown-' +
                          (getColNameForEl(tableHead?.cellIndex) !== ''
                            ? getColNameForEl(tableHead?.cellIndex)
                            : 'ClaimsFromLast')
                      )
                    : null
                })
              }
              break
            case 'TD':
              const table = document.getElementsByClassName('claims-grid')[0]
              const rows = table.querySelectorAll('tr')
              const rowsArray = Array.from(rows)

              const td = el as HTMLTableCellElement
              const rowIndex = rowsArray.findIndex((row) => row.contains(td))
              dataQaAttr(
                el,
                getColNameForEl(rows[0].cells[td?.cellIndex]?.cellIndex) +
                  '-row' +
                  rowIndex.toString()
              )
              break
            default:
              dataQaAttr(el, value + index.toString())
              break
          }
        }
      })
    })
  }

  attachAttr(tr, 'tr')
  attachAttr(td, 'td')
  attachAttr(input, 'input')
  attachAttr(button, 'button')
  attachAttr(dropdown, 'span')
  attachAttr(option, 'option')
  attachAttr(span, 'span')
}

export const postDataToServer = (
  authContext: IAuthContext,
  url: string,
  body: UserDetails
) => {
  const baseUrl = process.env.REACT_APP_API_USER_URL
  const token = authContext.getAccessToken(authContext.cognitoAuth)
  const idToken = authContext.getIdentityToken(authContext.cognitoAuth)
  const fullUrl = new URL(url, baseUrl)

  let callHeaders = new Headers()
  callHeaders.append('Authorization', 'Bearer ' + token)
  callHeaders.append('Identity', idToken)
  callHeaders.append('Content-Type', 'application/json')

  let requestOptions = {
    method: 'POST',
    headers: callHeaders,
    body: JSON.stringify(body),
  }

  fetch(fullUrl, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          throw new Error(`${errorData.message}`)
        })
      }
      return response.text()
    })
    .catch((error) => {
      console.log(error.message)
    })
}

export const applyStylesToElement = (selector: string, styles: string) => {
  const tooltipElement = document.querySelector(selector)
  if (tooltipElement) {
    tooltipElement.setAttribute('style', styles)
  }
}

export const getUserInitials = (username: string) => {
  return (
    username
      .split(' ')
      .slice(0, 2)
      .map((word) => word[0].toUpperCase())
      .join('') ?? ''
  )
}
