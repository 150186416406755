import { Alert, AlertTitle } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

interface PopupMessageProps {
  msg: string
  title: string
  dataQaAttributeAlert: string
  dataQaAttributeTextDiv: string
}

const PopupMessage = ({
  msg,
  title,
  dataQaAttributeAlert,
  dataQaAttributeTextDiv,
}: PopupMessageProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
      }}
    >
      <Alert
        severity="error"
        sx={{
          margin: '20px 0',
          borderLeft: '8px solid red',
          padding: '40px 20px',
          color: 'black',
          width: '300px',
        }}
        data-qa={dataQaAttributeAlert}
        icon={
          <ErrorOutlineIcon
            style={{
              fontSize: '3rem',
            }}
          />
        }
      >
        <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
        <div data-qa={dataQaAttributeTextDiv}>{msg}</div>
      </Alert>
    </div>
  )
}

export default PopupMessage
