import { CognitoAuth } from 'amazon-cognito-auth-js'
import { createContext, useContext } from 'react'
import IAuthContext from '../types/IAuthContext'
import { ICognitoSessionPayload } from '../types/ICognitoSessionPayload'
import { UserDetails } from '../models/UserDetails'

const fGetUserDisplayName = (cognitoUser: CognitoAuth): string => {
  if (cognitoUser.isUserSignedIn()) {
    const session = cognitoUser.getSignInUserSession()
    const idToken = session.getIdToken()
    const idPayload = idToken.decodePayload() as ICognitoSessionPayload

    return `${idPayload['given_name']} ${idPayload['family_name']}`
  }

  return ''
}

const fGetTenantId = (cognitoAuth: CognitoAuth): string => {
  if (cognitoAuth.isUserSignedIn()) {
    const session = cognitoAuth.getSignInUserSession()
    const idToken = session.getIdToken()
    const idPayload = idToken.decodePayload() as ICognitoSessionPayload

    return `${idPayload['custom:tenant_id']}`
  }
  return ''
}

const fGetAccessToken = (cognitoAuth: CognitoAuth): string => {
  if (cognitoAuth.isUserSignedIn()) {
    return cognitoAuth.getSignInUserSession().getAccessToken().getJwtToken()
  }

  return ''
}

const fGetIdentityToken = (cognitoAuth: CognitoAuth): string => {
  if (cognitoAuth.isUserSignedIn()) {
    return cognitoAuth.getSignInUserSession().getIdToken().getJwtToken()
  }

  return ''
}

const fIsCaasUser = (cognitoAuth: CognitoAuth): boolean => {
  if (cognitoAuth.isUserSignedIn()) {
    const userDetails = fGetUserDetails(cognitoAuth)
    return userDetails.groups.includes('SSO_Javalin_CAAS')
  }

  return false
}

const fGetUserDetails = (cognitoUser: CognitoAuth): UserDetails => {
  if (cognitoUser.isUserSignedIn()) {
    const session = cognitoUser.getSignInUserSession()
    const idToken = session.getIdToken()
    const idPayload = idToken.decodePayload() as ICognitoSessionPayload

    const userDetails = new UserDetails()
    userDetails.username = `${idPayload['cognito:username']}`
    userDetails.firstName = `${idPayload['given_name']}`
    userDetails.lastName = `${idPayload['family_name']}`
    userDetails.email = `${idPayload['email']}`
    userDetails.tenantId = `${idPayload['custom:tenant_id']}`
    userDetails.groups = `${idPayload['custom:user_groups']}`

    return userDetails
  }

  return new UserDetails()
}

// environment variables
const REACT_APP_TITLE = process.env.REACT_APP_TITLE
const REACT_APP_API_USER_URL = process.env.REACT_APP_API_USER_URL
const REACT_APP_COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID
const REACT_APP_COGNITO_TO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_TO_APP_CLIENT_ID
const REACT_APP_COGNITO_BASE = process.env.REACT_APP_COGNITO_BASE
const REACT_APP_COGNITO_BASE_CALLBACK =
  process.env.REACT_APP_COGNITO_BASE_CALLBACK

// Cognito settings
const poolId = REACT_APP_COGNITO_USER_POOL_ID as string
const baseUrl = REACT_APP_COGNITO_BASE as string
const domain = new URL(baseUrl).hostname as string
const clientId = REACT_APP_COGNITO_TO_APP_CLIENT_ID as string
const callbackUrl = `${REACT_APP_COGNITO_BASE_CALLBACK}callback` as string
const signoutUrl = `${REACT_APP_COGNITO_BASE_CALLBACK}`
const scopeArray = ['openid', 'email', 'profile']

const cognitoAuthOptions = {
  ClientId: clientId,
  AppWebDomain: domain,
  TokenScopesArray: scopeArray,
  RedirectUriSignIn: callbackUrl,
  RedirectUriSignOut: signoutUrl,
  UserPoolId: poolId,
}
export const cognitoAuth = new CognitoAuth(cognitoAuthOptions)
//cognitoAuth.useCodeGrantFlow()
cognitoAuth.userhandler = {
  onSuccess: function (result) {
    localStorage.setItem('hasHistory', 'true')
  },
  onFailure: function (err) {
    console.log('Sign in error!')
  },
}

const cognitoAuthContext: IAuthContext = {
  cognitoAuth: cognitoAuth,
  getUserDisplayName: (cognitoAuth: CognitoAuth) =>
    fGetUserDisplayName(cognitoAuth),
  getTenantId: (cognitoAuth: CognitoAuth) => fGetTenantId(cognitoAuth),
  getAccessToken: (cognitoAuth: CognitoAuth) => fGetAccessToken(cognitoAuth),
  getIdentityToken: (cognitoAuth: CognitoAuth) =>
    fGetIdentityToken(cognitoAuth),
  getUserDetails: (cognitoAuth: CognitoAuth) => fGetUserDetails(cognitoAuth),
  isUserCaas: (cognitoAuth: CognitoAuth) => fIsCaasUser(cognitoAuth),
}

export const AuthContext = createContext<IAuthContext>(cognitoAuthContext)

export const AuthProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext)
  return (
    <>
      <AuthContext.Provider value={authContext}>
        {children}
      </AuthContext.Provider>
    </>
  )
}
