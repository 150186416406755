import { useContext, useEffect } from 'react'
import { AuthContext } from '../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { postDataToServer } from './Utils'

const Callback = () => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const curUrl = window.location.href
    authContext.cognitoAuth.parseCognitoWebResponse(curUrl)

    if (!authContext.cognitoAuth.isUserSignedIn()) {
      navigate(-1)
    } else {
      insertOrUpdateUser()
      const savedRoute = localStorage.getItem('requestedUrl')
      if (savedRoute) {
        localStorage.removeItem('requestedUrl')
        navigate(savedRoute)
      } else {
        navigate('/claims')
      }
    }
  }, [authContext.cognitoAuth, navigate])

  const insertOrUpdateUser = () => {
    const userDetails = authContext.getUserDetails(authContext.cognitoAuth)
    postDataToServer(authContext, '/claims/user', userDetails)
  }

  return <></>
}

export default Callback
