import React, { useEffect, useState } from 'react'
import { GridColDef, useGridApiContext } from '@mui/x-data-grid'
import { Checkbox, FormControlLabel } from '@mui/material'
import { applyStylesToElement } from './Utils'

type CustomColumnsPanelProps = {
  columns: GridColDef[]
  visibleColumns: Set<string>
  setVisibleColumns: React.Dispatch<React.SetStateAction<Set<string>>>
}

const CustomColumnsPanel: React.FC<CustomColumnsPanelProps> = ({
  columns,
  visibleColumns,
  setVisibleColumns,
}) => {
  const apiRef = useGridApiContext()

  useEffect(() => {
    if (apiRef.current) {
      applyStylesToElement(
        '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiDataGrid-paper',
        'position: relative; top: -55px; margin-left: 70vw;'
      )
    }
  }, [apiRef])

  const handleColumnVisibilityChange = (field: string, isVisible: boolean) => {
    if (isVisible) {
      visibleColumns.add(field)
    } else {
      visibleColumns.delete(field)
    }
    setVisibleColumns(new Set(visibleColumns))
    apiRef.current.setColumnVisibility(field, isVisible)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
      {columns.map((column) => {
        return (
          <FormControlLabel
            key={column.field}
            control={
              <Checkbox
                data-qa={`column-panel-checkbox-${column.field}`}
                checked={visibleColumns.has(column.field)}
                onChange={(event) =>
                  handleColumnVisibilityChange(
                    column.field,
                    event.target.checked
                  )
                }
              />
            }
            label={column.headerName || column.field}
          />
        )
      })}
    </div>
  )
}

export default CustomColumnsPanel
