import React, { useEffect } from 'react'
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  Box,
  SxProps,
  Theme,
  Switch,
  FormControlLabel,
} from '@mui/material'
import { Dayjs } from 'dayjs'
import {
  DatePickerSlotsComponents,
  DateRangePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro'
import {
  GridFilterModel,
  GridFilterItem,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import { applyStylesToElement } from './Utils'

type CaasCustomFilterPanelProps = {
  onChange: (filterModel: GridFilterModel) => void
  activeFiltersCount: number
  filterDates: DateRange<Dayjs>
  filterClaimNumber: string
  filterGroupName: string
  filterRenderingProvider: string
  filterIsAssigned: boolean
  filterIsAdjudicated: boolean
  filterIsAppeal: boolean | undefined
  setActiveFiltersCount: React.Dispatch<React.SetStateAction<number>>
  setFilterDates: React.Dispatch<React.SetStateAction<DateRange<Dayjs>>>
  setFilterClaimNumber: React.Dispatch<React.SetStateAction<string>>
  setFilterGroupName: React.Dispatch<React.SetStateAction<string>>
  setFilterRenderingProvider: React.Dispatch<React.SetStateAction<string>>
  setFilterIsAssigned: React.Dispatch<React.SetStateAction<boolean>>
  setFilterIsAdjudicated: React.Dispatch<React.SetStateAction<boolean>>
  setFilterIsAppeal: React.Dispatch<React.SetStateAction<boolean | undefined>>
  reloadData?: () => void
}

const CaasCustomFilterPanel = ({
  onChange,
  activeFiltersCount,
  filterDates,
  filterClaimNumber,
  filterGroupName,
  filterRenderingProvider,
  filterIsAssigned,
  filterIsAdjudicated,
  filterIsAppeal,
  setFilterDates,
  setFilterClaimNumber,
  setFilterGroupName,
  setFilterRenderingProvider,
  setFilterIsAssigned,
  setFilterIsAdjudicated,
  setFilterIsAppeal,
  setActiveFiltersCount,
  reloadData,
}: CaasCustomFilterPanelProps) => {
  const [dates, setDates] = React.useState(filterDates)
  const [claimNumber, setClaimNumber] = React.useState(filterClaimNumber)
  const [groupName, setGroupName] = React.useState(filterGroupName)
  const [renderingProvider, setRenderingProvider] = React.useState(
    filterRenderingProvider
  )
  const [isAssigned, setIsAssigned] = React.useState(filterIsAssigned)
  const [isAdjudicated, setIsAdjudicated] = React.useState(filterIsAdjudicated)
  const [isAppeal, setIsAppeal] = React.useState(filterIsAppeal)
  const datesCurrent = filterDates
  const claimNumberCurrent = filterClaimNumber
  const groupNameCurrent = filterGroupName
  const renderingProviderCurrent = filterRenderingProvider
  const isAssignedCurrent = filterIsAssigned
  const isAdjudicatedCurrent = filterIsAdjudicated
  const isAppealCurrent = filterIsAppeal
  const pickerRef = React.useRef<HTMLDivElement>(null)

  const apiCtx = useGridApiContext()

  useEffect(() => {
    if (apiCtx.current) {
      applyStylesToElement(
        '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiDataGrid-paper',
        'position: relative !important; top: -55px !important;'
      )
    }
  }, [apiCtx])

  const handleClaimNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const trimmedValue =
      event.target.value.trim() === '' ? '' : event.target.value
    setClaimNumber(trimmedValue)
  }

  const handleRenderingProviderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const trimmedValue =
      event.target.value.trim() === '' ? '' : event.target.value
    setRenderingProvider(trimmedValue)
  }

  const handleGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const trimmedValue =
      event.target.value.trim() === '' ? '' : event.target.value
    setGroupName(trimmedValue)
  }

  const handleIsAssignedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAssigned(event.target.checked)
  }

  const handleIsAppealChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAppeal(event.target.checked)
  }

  const handleIsAdjudicatedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAdjudicated(event.target.checked)
  }
  const handleApplyFilter = () => {
    const filterItems: GridFilterItem[] = []

    // Claim Number filter
    if (claimNumber !== '') {
      filterItems.push({
        field: 'claimNumber',
        operator: 'contains',
        value: claimNumber,
      })
    }

    // Submit Date filter
    if (dates[0] !== null || dates[1] !== null) {
      const formattedStartDate = dates[0]
        ? dates[0].format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        : null
      const formattedEndDate = dates[1]
        ? dates[1].format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        : null

      filterItems.push({
        field: 'submitDate',
        operator: 'between',
        value: [formattedStartDate, formattedEndDate],
      })
    }

    // Rendering Provider filter
    if (renderingProvider !== '') {
      filterItems.push({
        field: 'renderingProvider',
        operator: 'contains',
        value: renderingProvider,
      })
    }

    // Group Name filter
    if (groupName !== '') {
      filterItems.push({
        field: 'groupName',
        operator: 'contains',
        value: groupName,
      })
    }

    // Is Appeal filter
    if (isAppeal) {
      filterItems.push({
        field: 'isAppeal',
        operator: 'eq',
        value: isAppeal,
      })
    }

    // Is Adjudicated filter
    filterItems.push({
      field: 'isAdjudicated',
      operator: 'eq',
      value: isAdjudicated,
    })

    // Is Assigned filter
    filterItems.push({
      field: 'isAssigned',
      operator: 'eq',
      value: isAssigned,
    })
    const filterModel: GridFilterModel = {
      items: filterItems,
    }

    const activeCount = getActiveFiltersCount()
    setActiveFiltersCount(activeCount)

    onChange(filterModel)

    setFilterClaimNumber(claimNumber)
    setFilterDates([dates[0], dates[1]])
    setFilterRenderingProvider(renderingProvider)
    setFilterGroupName(groupName)
    setFilterIsAdjudicated(isAdjudicated)
    setFilterIsAppeal(isAppeal)
    setFilterIsAssigned(isAssigned)

    apiCtx.current.hideFilterPanel()
  }

  const handleClearFilter = () => {
    resetInputs()
    if (reloadData) {
      reloadData()
    }
    setActiveFiltersCount(0)

    apiCtx.current.hideFilterPanel()
  }

  const resetInputs = () => {
    setClaimNumber('')
    setGroupName('')
    setRenderingProvider('')
    setDates([null, null])
    setIsAppeal(false)
    setIsAdjudicated(false)
    setIsAssigned(true)
  }

  const getActiveFiltersCount = () => {
    let count = 0
    if (claimNumber !== '') count++
    if (groupName !== '') count++
    if (renderingProvider !== '') count++
    if (dates[0] !== null || dates[1] !== null) count++
    if (isAdjudicated) count++
    if (isAppeal) count++
    if (!isAssigned) count++
    return count
  }

  const divStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
    fontWeight: '700',
  }

  const spanStyle: React.CSSProperties = {
    marginBottom: '4px',
  }

  const customSx: SxProps<Theme> = {
    width: '100%',
    '& legend': { display: 'none' },
    '& .MuiInputLabel-shrink': {
      opacity: 0,
      transition: 'all 0.2s ease-in',
    },
  }

  React.useEffect(() => {
    setClaimNumber(filterClaimNumber)
  }, [
    filterClaimNumber,
    filterGroupName,
    filterRenderingProvider,
    filterDates,
    filterIsAppeal,
    filterIsAdjudicated,
    filterIsAssigned,
  ])

  React.useEffect(() => {
    if (pickerRef.current) {
      const labels = pickerRef.current.getElementsByTagName('label')
      Array.from(labels).forEach((label) => {
        if (label.textContent === 'From') {
          const fromInput =
            label.nextElementSibling?.getElementsByTagName('input')[0]
          if (fromInput) {
            fromInput.setAttribute('data-qa', 'submit-date-from')
          }
        } else if (label.textContent === 'To') {
          const toInput =
            label.nextElementSibling?.getElementsByTagName('input')[0]
          if (toInput) {
            toInput.setAttribute('data-qa', 'submit-date-to')
          }
        }
      })
    }
  }, [])

  const hasMounted = React.useRef(false)

  React.useEffect(() => {
    hasMounted.current = true
  }, [])

  React.useEffect(() => {
    if (hasMounted.current && activeFiltersCount === 0) {
      resetInputs()
    }
  }, [activeFiltersCount])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '30px',
        width: '400px',
        minHeight: '0',
      }}
    >
      <div style={divStyle}>
        <span style={spanStyle} data-qa="col-caas">
          CAAS
        </span>
        <div>
          <FormControlLabel
            data-qa="switch-assigned"
            labelPlacement="top"
            control={
              <Switch checked={isAssigned} onChange={handleIsAssignedChange} />
            }
            label="Assigned"
          />
          <FormControlLabel
            data-qa="switch-adjudicated"
            labelPlacement="top"
            control={
              <Switch
                checked={isAdjudicated}
                onChange={handleIsAdjudicatedChange}
              />
            }
            label="Adjudicated"
          />
          <FormControlLabel
            data-qa="switch-appeal"
            labelPlacement="top"
            control={
              <Switch checked={isAppeal} onChange={handleIsAppealChange} />
            }
            label="Appeal"
          />
        </div>
      </div>
      <div style={divStyle}>
        <span style={spanStyle} data-qa="col-claimNumber">
          Claim Number
        </span>
        <TextField
          sx={customSx}
          data-qa="textField-claimNumber"
          size="small"
          label="Claim Number"
          onChange={handleClaimNumberChange}
          value={claimNumber}
        />
      </div>
      <div style={divStyle}>
        <span style={spanStyle} data-qa="col-submitDate">
          Submit Date
        </span>
        <div ref={pickerRef} style={{ display: 'flex', flexDirection: 'row' }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: 'From', end: 'To' }}
          >
            <DateRangePicker
              sx={customSx}
              value={dates}
              onChange={(newValue) => {
                setDates(newValue)
              }}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div style={divStyle}>
        <span style={spanStyle} data-qa="col-renderingProvider">
          Rendering Provider
        </span>
        <TextField
          sx={customSx}
          data-qa="textField-renderingProvider"
          size="small"
          label="Rendering Provider"
          onChange={handleRenderingProviderChange}
          value={renderingProvider}
        />
      </div>
      <div style={divStyle}>
        <span style={spanStyle} data-qa="col-groupName">
          Group Name
        </span>
        <TextField
          sx={customSx}
          data-qa="textField-groupName"
          size="small"
          label="Group Name"
          onChange={handleGroupNameChange}
          value={groupName}
        />
      </div>
      <div style={divStyle}>
        <Button
          sx={{ width: '100%', fontWeight: '700' }}
          data-qa="button-apply-filter"
          variant="contained"
          onClick={handleApplyFilter}
        >
          APPLY FILTERS
        </Button>
      </div>
      <div style={divStyle}>
        <Button
          sx={{ width: '100%', fontWeight: '700' }}
          data-qa="button-reset-filter"
          variant="text"
          onClick={handleClearFilter}
        >
          RESET FILTERS
        </Button>
      </div>
    </Box>
  )
}

export default CaasCustomFilterPanel
