import { IApiResponse } from './IApiResponse'
import { IClaim } from './IClaim'

export class GetClaimsResponse implements IApiResponse {
  apiRequestStatusText: string = ''
  apiRequestStatusNumber?: number
  claimSearchResults: Array<IClaim> = new Array<IClaim>(0)
  claimCount: number = 0
  tenantName: string = ''
  tenantAcronym: string = ''
}
