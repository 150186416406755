import { createTheme } from '@mui/material/styles'
import styles from './styles.module.scss'

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
  }
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: styles.colorPrimary,
      light: styles.colorPrimaryLight,
      dark: styles.colorPrimaryDark,
      contrastText: styles.colorContrastLight,
    },
    secondary: {
      main: styles.colorSecondary,
      light: styles.colorSecondaryLight,
      dark: styles.colorSecondaryDark,
      contrastText: styles.colorContrastLight,
    },
    success: {
      main: styles.colorSuccess,
      light: styles.colorSuccessLight,
      dark: styles.colorSuccessDark,
      contrastText: styles.colorContrastLight,
    },
    error: {
      main: styles.colorError,
    },
    warning: {
      main: styles.colorWarning,
    },
  },
  typography: {
    fontFamily: styles.fontPrimary,
    h1: { size: styles.textXl },
    h2: { size: styles.textLg },
    h3: { size: styles.textMd },
    h4: { size: styles.textSm },
    h5: { size: styles.textXs },
  },
})

export default theme
