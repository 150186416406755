import { ColorChart } from './ColorChart'
import './ColorChart.scss'

export const ColorChartList = () => {
  const colors = [
    { color: '#22E2EE', label: 'Crown' },
    { color: '#E657FB', label: 'RCT' },
    { color: '#32E3A8', label: 'Filling' },
    { color: '#E04862', label: 'Decay' },
    { color: '#629EFF', label: 'Impaction Impacted' },
    { color: '#FFCD31', label: 'Impaction Erupted' },
  ]
  return (
    <div className="color-chart-list-container">
      {colors.map((colorChart, index) => (
        <ColorChart key={index} colorChartProps={colorChart} />
      ))}
    </div>
  )
}
