import { IApiResponse } from "./IApiResponse"

export class PostClinicalAgreementResponse implements IApiResponse {
    apiRequestStatusNumber?: number
    apiRequestStatusText: string

    constructor(statusText: string, statusNumber?: number) {
        this.apiRequestStatusText = statusText
        this.apiRequestStatusNumber = statusNumber
    }
}