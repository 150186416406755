import { Alert, AlertTitle, Portal, Snackbar } from '@mui/material'

export interface ErrorSnackbarProps {
  errorMessage: string
  title: string
  isOpenErrorSnackbar: boolean
  handleCloseSnackbar?: any
}

const ErrorSnackbar = ({ errorMessage, title, isOpenErrorSnackbar, handleCloseSnackbar }: ErrorSnackbarProps) => {


  return (
    <div>
      <Portal>
        <Snackbar
          open={isOpenErrorSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            <AlertTitle>{title}</AlertTitle>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  )
}

export default ErrorSnackbar
