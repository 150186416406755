import { useContext, useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router'
import '../sass/base/_global.scss'
import '../sass/components/grid/_grid.scss'
import '../sass/components/grid/_claim-grid.scss'
import theme from '../themes/muiTheme'
import tenantConfig from '../tenantConfig.json'
import { ClaimsFilter } from '../models/ClaimsFilter'
import { AuthContext } from '../auth/AuthProvider'
import { formatDate } from '../services/StringFormater'
import CustomDataGridMenu from './CustomDataGridMenu'
import CustomFilterPanel from './CustomFilterPanel'
import { ClaimsApi } from '../api/ClaimsApi'
import { RiFilterLine } from 'react-icons/ri'
import {
  DataGridPremium,
  GridColDef,
  GridColTypeDef,
  GridFilterModel,
  GridRenderCellParams,
  getGridStringOperators,
  getGridDateOperators,
  GRID_STRING_COL_DEF,
  GridPaginationModel,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import MuiPagination from '@mui/material/Pagination'
import { TablePaginationProps } from '@mui/material/TablePagination'
import { GetClaimsResponse } from '../models/api/GetClaimsResponse'
import ErrorSnackbar from './ErrorSnackbar'
import { IClaim } from '../models/api/IClaim'
import {
  gridFilteredTopLevelRowCountSelector,
  gridPageSizeSelector,
  useGridRootProps,
} from '@mui/x-data-grid'
import { Button, Paper } from '@mui/material'
import { addDataQaAttributesToPagination } from './Utils'
import CustomColumnsPanel from './CustomColumnsPanel'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import CustomSortIcon from './CustomSortIcon'

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const getPageCount = (rowCount: number, pageSize: number): number => {
    if (pageSize > 0 && rowCount > 0) {
      return Math.ceil(rowCount / pageSize)
    }

    return 0
  }

  const apiRef = useGridApiContext()
  const rootProps = useGridRootProps()
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const visibleTopLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector
  )

  const pageCount = getPageCount(
    rootProps.rowCount ?? visibleTopLevelRowCount,
    pageSize
  )

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1)
      }}
      showFirstButton
      showLastButton
    />
  )
}

function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />
}

type SearchResultsProps = {
  searchString: string
}

export function SearchResults(props: SearchResultsProps) {
  const authProvider = useContext(AuthContext)
  const [data, setData] = useState<IClaim[]>([])
  const [cols, setCols] = useState<GridColDef[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowCountState, setRowCountState] = useState<number>(rowCount)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [endDate] = useState(new Date())
  const [startDate] = useState(
    new Date(new Date().setDate(endDate.getDate() - 30))
  )
  const [isLoading, setIsLoading] = useState(true)
  const [activeFiltersCount, setActiveFiltersCount] = useState(0)

  const location = useLocation()
  const [tenantId] = useState(
    authProvider.getTenantId(authProvider.cognitoAuth)
  )

  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })

  const [filterDates, setFilterDates] = useState<DateRange<Dayjs>>([null, null])
  const [filterClaimNumber, setFilterClaimNumber] = useState<string>('')
  const [filterGroupName, setFilterGroupName] = useState<string>('')
  const [filterStatus, setFilterStatus] = useState('select')
  const [filterRenderingProvider, setFilterRenderingProvider] =
    useState<string>('')

  const searchParam = new URLSearchParams(location.search)
  const searchTerm = searchParam.get('searchTerm' || '') || ''

  const defaultFilter = {
    tenantId: authProvider.getTenantId(authProvider.cognitoAuth),
    submitDateTo: endDate.toJSON(),
    submitDateFrom: new Date(
      new Date().setDate(startDate.getDate() - 364)
    ).toJSON(),
    serviceDateTo: endDate.toJSON(),
    serviceDateFrom: startDate.toJSON(),
    pageNumber: 1,
    pageSize: pageSize,
    claimNumber: '%20',
    status: '%20',
    renderingProvider: '%20',
    groupName: '%20',
    sortOrder: 'DESC',
    sortField: 'SubmitDate',
    searchTerm: searchTerm ? encodeURIComponent(searchTerm) : '%20',
    userName: '',
  }

  const [isCallWaiting, setIsCallWaiting] = useState(false)

  const [currentFilter, setCurrentFilter] =
    useState<ClaimsFilter>(defaultFilter)

  const updateClaimsFilter = (filterModel: GridFilterModel) => {
    let updateValues = defaultFilter

    filterModel.items.forEach((item) => {
      item.field === 'claimNumber'
        ? (updateValues.claimNumber = item.value)
        : ''
      item.field === 'status' ? (updateValues.status = item.value) : ''
      item.field === 'renderingProvider'
        ? (updateValues.renderingProvider = item.value)
        : ''
      item.field === 'groupName' ? (updateValues.groupName = item.value) : ''
      if (item.field === 'submitDate') {
        updateValues.serviceDateFrom = item.value[0]
        updateValues.serviceDateTo = item.value[1]
        updateValues.submitDateFrom = item.value[0]
        updateValues.submitDateTo = item.value[1]
      }
    })

    setCurrentFilter((prev) => ({
      ...prev,
      ...updateValues,
    }))
  }

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }

  const getClaims = async (filter: ClaimsFilter) => {
    setData([])
    if (isLoading !== true) setIsLoading(true)

    let cr: GetClaimsResponse = await ClaimsApi.getClaims(filter)

    if (
      !cr.apiRequestStatusNumber ||
      String(cr.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(cr)
      setErrorSnackbarProps({
        errorMessage: cr.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${cr.apiRequestStatusNumber ?? 'unknown'}`,
      })
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setData(cr.claimSearchResults)
    setRowCount(cr.claimCount)
    setCols(buildColumns(cr.tenantAcronym))
    addDataQaAttributes()
  }

  const sx = {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700',
      color: `${theme.palette.primary.main}`,
    },
    '& .MuiDataGrid-cell': {
      fontWeight: '700',
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-toolbarContainer': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '& button': {
        color: `${theme.palette.primary.main}`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.primary.main}`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.primary.main}`,
      },
    },
    '& .MuiTablePagination-caption[id]': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .MuiTablePagination-input': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .MuiTablePagination-selectLabel': {
      marginTop: 'initial !important',
      marginBottom: 'initial !important',
    },
    '& .MuiTablePagination-displayedRows': {
      marginTop: 'initial !important',
      marginBottom: 'initial !important',
    },
  }

  const customFilterColumnType: GridColTypeDef = {
    ...GRID_STRING_COL_DEF,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains'
    ),
  }

  const customFilterColumnTypeDate: GridColTypeDef = {
    ...GRID_STRING_COL_DEF,
    filterOperators: getGridDateOperators().filter(
      (operator) =>
        operator.value === 'onOrBefor' || operator.value === 'onOrAfter'
    ),
  }

  const buildColumns = (acronym: string): GridColDef[] => {
    const index = tenantConfig.tenants.findIndex(
      (tenant) => tenant.id === acronym
    )

    return [
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.claimId || 'Claim Id',
        field: 'claimId',
        type: 'string',
        width: 150,
        flex: 1,
        align: 'left',
        filterable: false,
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`ClaimId-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.claimId}
            </span>
          )
        },
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.claimNumber ||
          'Claim Number',
        field: 'claimNumber',
        type: 'string',
        width: 150,
        flex: 1,
        align: 'left',
        filterable: true,
        renderCell: (index: GridRenderCellParams) => {
          return (
            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: '20px',
                padding: '0 20px',
                minWidth: '170px',
                minHeight: '32px',
                fontWeight: '600',
                textTransform: 'none',
                color: 'white',
                '&:hover': {
                  color: 'white',
                },
              }}
              href={`/Claims/${index.row.claimNumber}`}
              target="_blank"
              rel="noreferrer"
              data-qa={`ClaimNumber-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.claimNumber}
            </Button>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.status || 'Status',
        field: 'status',
        type: 'string',
        width: 100,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`Status-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.status}
            </span>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.submitDate ||
          'Submit Date',
        field: 'submitDate',
        type: 'date',
        width: 200,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`SubmitDate-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {formatDate(index.row.submitDate, true, 'dot')}
            </span>
          )
        },
        ...customFilterColumnTypeDate,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.renderingProvider ||
          'Rendering Provider',
        field: 'renderingProvider',
        type: 'string',
        width: 250,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`RenderingProvider-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.renderingProvider}
            </span>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.group || 'Group Name',
        field: 'groupName',
        type: 'string',
        width: 200,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`GroupName-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.groupName}
            </span>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns
            .assignedUserFullName || 'Assigned User',
        field: 'assignedUserFullName',
        type: 'string',
        width: 200,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`AssignedUser-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.assignedUserFullName}
            </span>
          )
        },
        ...customFilterColumnType,
      },
    ]
  }

  function addDataQaAttributes(): void {
    // Find all elements with class "MuiDataGrid-columnHeaderTitle"
    const elements = document.querySelectorAll('.MuiDataGrid-columnHeaderTitle')

    // Loop through each element and modify its attributes
    elements.forEach((element) => {
      const innerText = (element as HTMLElement).innerText.replace(/\s+/g, '') // Remove all whitespace from inner text
      const dataQaValue = `columnHeaderTitle-${innerText}` // Create value for data-qa attribute
      element.setAttribute('data-qa', dataQaValue) // Set data-qa attribute
    })
    addDataQaAttributesToPagination()
  }

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.pageSize !== pageSize) {
      onPageSizeChange(newPaginationModel.pageSize)
    }

    const newPage = newPaginationModel.page
    const lastPage = Math.ceil(rowCount / newPaginationModel.pageSize) - 1

    if (newPage > lastPage) {
      setPage(lastPage)
      setCurrentFilter((prev) => ({
        ...prev,
        pageNumber: lastPage + 1,
      }))
    } else {
      setPage(newPage)
      setCurrentFilter((prev) => ({
        ...prev,
        pageNumber: newPage + 1,
      }))
    }

    setPaginationModel(newPaginationModel)
  }

  const onPageSizeChange = (newPageSize: any) => {
    setPageSize(newPageSize)

    setCurrentFilter((prev) => ({
      ...prev,
      pageSize: newPageSize,
    }))
  }

  const getSearchString = (query: string) => {
    currentFilter.searchTerm = query
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    )
  }, [rowCount, setRowCountState])

  const computedFilter = useMemo(() => {
    return {
      ...defaultFilter,
      pageNumber: page + 1,
      pageSize: pageSize,
      claimNumber: `%20`,
      status: `%20`,
      renderingProvider: `%20`,
      groupName: `%20`,
    }
  }, [page, pageSize, props.searchString])

  useEffect(() => {
    if (isCallWaiting) return

    getClaims(computedFilter)
  }, [computedFilter])

  useEffect(() => {
    addDataQaAttributes()
  }, [data])

  type filterParam = {
    field: string
    value: string
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    setPage(0)
    updateClaimsFilter(filterModel)

    const params: filterParam[] = []

    filterModel.items.map((item) => {
      item.value ? params.push({ field: item.field, value: item.value }) : null
    })

    getFilteredData(params)
  }

  let dateFrom: string = new Date(
    new Date().setDate(startDate.getDate() - 364)
  ).toJSON()
  let dateTo: string = endDate.toJSON()
  let claimNumber: string,
    status: string,
    renderingProvider: string,
    groupName: string

  const isDateValid = (dateString: string): boolean => {
    const timestamp = Date.parse(dateString)
    return !isNaN(timestamp)
  }

  const getFilteredData = async (filterParams: filterParam[]) => {
    setIsCallWaiting(true)
    filterParams
      .map((param) => {
        const field = param.field.includes('status')
          ? param.field.replace('status', 'processingStatus')
          : param.field

        if (param.field.includes('claimNumber')) {
          claimNumber = param.value
        }

        if (param.field.includes('status')) {
          status = param.value
        }

        if (param.field.includes('submitDate')) {
          if (isDateValid(param.value[0])) {
            dateFrom = param.value[0]
          }

          if (isDateValid(param.value[1])) {
            dateTo = param.value[1]
          }
        }

        if (param.field.includes('renderingProvider')) {
          renderingProvider = param.value
        }

        if (param.field.includes('groupName')) {
          groupName = param.value
        }

        return `${encodeURIComponent(
          field.charAt(0).toUpperCase() + field.slice(1)
        )}=${encodeURIComponent(param.value)}`
      })
      .join('&')
    const finalFilter: ClaimsFilter = {
      tenantId: tenantId,
      claimNumber: claimNumber,
      status: status,
      renderingProvider: renderingProvider,
      groupName: groupName,
      submitDateTo: dateTo ? dateTo : currentFilter.submitDateTo,
      submitDateFrom: dateFrom ? dateFrom : currentFilter.submitDateFrom,
      serviceDateTo: '',
      serviceDateFrom: '',
      pageNumber: 1,
      pageSize: pageSize,
      sortOrder: 'DESC',
      sortField: 'SubmitDate',
      searchTerm: searchTerm ? encodeURIComponent(searchTerm) : '%20',
      userName: '',
    }
    setIsLoading(true)
    getClaims(finalFilter)
  }

  const [currentSearchTerm, setCurrentSearchTerm] = useState(props.searchString)

  function reloadData() {
    setFilterClaimNumber('')
    setFilterStatus('select')
    setFilterDates([null, null])
    setFilterRenderingProvider('')
    setFilterGroupName('')

    getClaims(computedFilter)
  }

  useEffect(() => {
    const searchParam = new URLSearchParams(location.search)
    const newSearchTerm = searchParam.get('searchTerm') || ''

    if (newSearchTerm !== currentSearchTerm) {
      setCurrentSearchTerm(newSearchTerm)

      const updatedFilter = {
        ...defaultFilter,
        searchTerm: encodeURIComponent(newSearchTerm),
      }
      setCurrentFilter(updatedFilter)
      getClaims(updatedFilter)
    }
  }, [location.search])

  const defaultCols = new Set<string>([
    'claimNumber',
    'status',
    'submitDate',
    'renderingProvider',
    'groupName',
    'assignedUserFullName',
  ])
  const [visibleColumns, setVisibleColumns] = useState(defaultCols)

  return (
    <div
      className="app"
      style={{
        padding: '20px',
        display: 'flex',
        marginTop: '30px',
      }}
    >
      <h3 style={{ fontWeight: '700', fontSize: '22px' }}>
        Search Results for &quot;{searchTerm}&quot;:
      </h3>

      <div
        style={{
          display: 'flex',
          height: '68vh',
        }}
      >
        <DataGridPremium
          loading={isLoading}
          slots={{
            columnSortedAscendingIcon: () => <CustomSortIcon direction="asc" />,
            columnSortedDescendingIcon: () => (
              <CustomSortIcon direction="desc" />
            ),
            pagination: CustomPagination,
            toolbar: () => (
              <CustomDataGridMenu
                claimsFilter={currentFilter}
                handleSearchInput={getSearchString}
                activeFiltersCount={activeFiltersCount}
              />
            ),
            filterPanel: () => (
              <Paper sx={{ height: '535px' }}>
                <CustomFilterPanel
                  onChange={onFilterChange}
                  activeFiltersCount={activeFiltersCount}
                  setActiveFiltersCount={setActiveFiltersCount}
                  filterClaimNumber={filterClaimNumber}
                  setFilterClaimNumber={setFilterClaimNumber}
                  filterGroupName={filterGroupName}
                  setFilterGroupName={setFilterGroupName}
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  filterRenderingProvider={filterRenderingProvider}
                  setFilterRenderingProvider={setFilterRenderingProvider}
                  filterDates={filterDates}
                  setFilterDates={setFilterDates}
                  reloadData={reloadData}
                />
              </Paper>
            ),
            columnsPanel: () => (
              <CustomColumnsPanel
                columns={cols}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
              />
            ),
            openFilterButtonIcon: () => <RiFilterLine />,
          }}
          onFilterModelChange={onFilterChange}
          filterMode="server"
          rows={data}
          rowCount={rowCountState}
          columns={cols}
          pageSizeOptions={[10, 25, 50, 100, 200]}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          onColumnVisibilityModelChange={() => {
            setTimeout(() => {
              addDataQaAttributes()
            }, 10)
          }}
          initialState={{
            columns: { columnVisibilityModel: { claimId: false } },
          }}
          getRowId={(row) => row.claimId}
          sx={sx}
        />
        <ErrorSnackbar
          errorMessage={errorSnackbarProps.errorMessage}
          title={errorSnackbarProps.title}
          isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
          handleCloseSnackbar={handleErrorSnackbarClose}
        ></ErrorSnackbar>
      </div>
    </div>
  )
}

export default SearchResults
