import { IApiResponse } from './IApiResponse'
import { ICaasClaim } from './ICaasClaim'

export class GetCaasClaimsResponse implements IApiResponse {
  apiRequestStatusText: string = ''
  apiRequestStatusNumber?: number
  claimSearchResults: Array<ICaasClaim> = new Array<ICaasClaim>(0)
  claimCount: number = 0
  tenantName: string = ''
  tenantAcronym: string = ''
}
