export const formatDate = (
  date: string,
  yearFirst: boolean = false,
  outputFormat: string = 'default',
  includeTime: boolean = false
) => {
  if (date === 'n/a' || date === undefined || date === null) {
    return 'n/a'
  }

  const dateParts = date.split('T')
  const dateSection = dateParts[0]
  const timeSection = dateParts.length > 1 ? dateParts[1] : ''

  const [year, month, day] = dateSection.split('-')
  const [hours, minutes] = timeSection.split(':')

  // Decide format based on outputFormat parameter
  if (outputFormat === 'dot') {
    return includeTime
      ? `${month}.${day}.${year} ${hours}:${minutes}` // "MM.DD.YYYY HH:mm" format
      : `${month}.${day}.${year}` // "MM.DD.YYYY" format
  } else {
    // Default or other formats based on yearFirst flag
    if (yearFirst) {
      return `${year}-${month}-${day} ${hours}:${minutes}`
    } else {
      return `${month}:${day}:${year} ${hours}:${minutes}`
    }
  }
}
