import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { GetClaimResponse } from '../../models/api/GetClaimResponse'
import { ClaimCommentView } from '../../models/ClaimCommentView'
import '../../sass/base/_global.scss'
import '../../sass/components/details/_details-comments.scss'
import { formatDate } from '../../services/StringFormater'
import { Avatar } from '@mui/material'
import { getUserInitials } from '../Utils'

export const ClaimDetailsCommentsTable = (props: {
  tenantId: string
  userName: string
  comments: ClaimCommentView[]
  claim: GetClaimResponse
}) => {
  const [claimComments, updateComments] = useState(
    new Array<ClaimCommentView>(0)
  )
  const [userTenantId, setUserTenantId] = useState('')
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  const { comments } = props

  const updateState = useCallback(async () => {
    updateComments(comments)
  }, [comments])

  useEffect(() => {
    updateState()
  }, [comments])

  useEffect(() => {
    if (token && userTenantId) {
      updateState()
    }
  }, [userTenantId, token])

  useEffect(() => {
    setToken(authContext.getAccessToken(authContext.cognitoAuth))
    setUserTenantId(authContext.getTenantId(authContext.cognitoAuth))
  }, [authContext])

  const getDate = (dateTimeString: string) => {
    const [datePart] = dateTimeString.split(' ')
    return datePart
  }

  const getTime = (dateTimeString: string) => {
    const [, timePart] = dateTimeString.split(' ')
    return timePart
  }

  const toSorted = (comments: any) => {
    return comments.sort((a: any, b: any) => {
      if (a.lineNumber !== b.lineNumber) {
        return a.lineNumber - b.lineNumber
      }
      return (
        new Date(b.commentDate).getTime() - new Date(a.commentDate).getTime()
      )
    })
  }

  const renderCommentsTable = () => {
    return toSorted(claimComments).map(
      (
        mappedComments: {
          lineNumber: number
          commentDate: string
          user: string
          comments: string
        },
        index: any
      ) => {
        const { lineNumber, commentDate, user, comments } = mappedComments
        return (
          <tr data-qa={'tr-' + index} key={index}>
            <td data-qa={'td-comment-card-' + index} colSpan={4}>
              <div
                className="td-card"
                style={{
                  border: '1px solid #cccccc',
                  borderRadius: '10px',
                  padding: '10px',
                  display: 'flex',
                }}
              >
                <div className="comment-card-container">
                  <div className="comment-card-row">
                    <div className="left-group">
                      <div className="comment-card-column">
                        <span>
                          <Avatar data-qa={'comment-card-avatar-' + user}>
                            {getUserInitials(user)}
                          </Avatar>
                        </span>
                      </div>
                      <div className="comment-card-column">
                        <span
                          style={{ fontWeight: 'bold' }}
                          data-qa={'div-userName-value-' + index}
                        >
                          {user}
                        </span>
                        <span data-qa={'div-commentDate-value-' + index}>
                          {getDate(formatDate(commentDate, true, 'dot', true))}
                          <br></br>
                          {getTime(formatDate(commentDate, true, 'dot', true))}
                        </span>
                      </div>
                    </div>
                    <div className="comment-card-column right-align">
                      <span style={{ fontWeight: 'bold' }}>Line</span>
                      <span data-qa={'div-lineNumber-value-' + index}>
                        {lineNumber}
                      </span>
                    </div>
                  </div>
                  <div className="comment-card-row">
                    <span data-qa={'div-comment-value-' + index}>
                      {comments}
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )
      }
    )
  }

  return (
    <div>
      <table id="commentsTable">
        <tbody>{renderCommentsTable()}</tbody>
      </table>
    </div>
  )
}

export default ClaimDetailsCommentsTable
