import { useState, useContext, useEffect, useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider'
import { GetClaimHistoryResponse } from '../../models/api/GetClaimHistoryResponse'
import { IClaimHistory } from '../../models/api/IClaimHistory'
import { IClaimlinesHistory } from '../../models/api/IClaimlinesHistory'
import { ClaimsApi } from '../../api/ClaimsApi'
import { BsLink45Deg } from 'react-icons/bs'
import { LinearProgress } from '@mui/material'
import { formatDate } from '../../services/StringFormater'
import React from 'react'

export const ClaimsDetailsHistoryTable: React.FC = () => {
  const [claimHistory, setClaimHistory] = useState<IClaimHistory[] | null>(null)
  const [userTenantId, setUserTenantId] = useState('')
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const [isUserCaas, setUserCass] = useState(
    authContext.isUserCaas(authContext.cognitoAuth)
  )

  const { search } = useLocation()
  const searchParam = React.useMemo(() => new URLSearchParams(search), [search])

  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
  const { claimNumber } = useParams()

  useEffect(() => {
    setToken(authContext.getAccessToken(authContext.cognitoAuth))
    if (isUserCaas && searchParam.get('clinician') == 'javalin') {
      setUserTenantId(localStorage.getItem('caas_tenant') || '""')
    } else {
      setUserTenantId(authContext.getTenantId(authContext.cognitoAuth))
    }
  }, [authContext])

  useEffect(() => {
    if (userTenantId) {
      updateState().catch((error) => {
        console.error(error)
      })
    }
  }, [userTenantId])

  const updateState = useCallback(async () => {
    try {
      const getClaimHistoryResponse: GetClaimHistoryResponse =
        await ClaimsApi.getClaimHistory(userTenantId, claimNumber!, token)
      setClaimHistory(getClaimHistoryResponse.claimHistory)

      if (
        !getClaimHistoryResponse.apiRequestStatusNumber ||
        String(getClaimHistoryResponse.apiRequestStatusNumber)[0] !== '2'
      ) {
        setErrorSnackbarProps({
          errorMessage: getClaimHistoryResponse.apiRequestStatusText,
          isOpenErrorSnackbar: true,
          title: `Request status: ${
            getClaimHistoryResponse.apiRequestStatusNumber ?? 'unknown'
          }`,
        })
      }
    } catch (error) {
      console.error('Error fetching claim history:', error)
      setErrorSnackbarProps({
        errorMessage: 'An error occurred while fetching claim history.',
        isOpenErrorSnackbar: true,
        title: 'Error',
      })
    }
  }, [userTenantId, claimNumber, token, claimHistory])

  return (
    <div style={{ overflowY: 'scroll', maxHeight: '480px', width: '100%' }}>
      {claimHistory ? (
        claimHistory.length === 0 ? (
          <div>No records found.</div>
        ) : (
          claimHistory?.map((item: IClaimHistory) => {
            return (
              <div key={item.claimNumber} className="history-table-data">
                <table key={item.claimNumber} className="history-table">
                  <tbody>
                    <tr>
                      <td
                        colSpan={6}
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'left',
                          padding: '8px',
                          textTransform: 'uppercase',
                          fontWeight: '600',
                          color: '#573D82',
                        }}
                      >
                        <span
                          style={{
                            padding: '10px',
                            borderBottom: '4px solid #573D82',
                          }}
                          data-qa={`history-claim-number-${item.claimNumber}`}
                        >
                          Claim #:{' '}
                          {item.isJavalinClaim ? (
                            <a
                              href={`/claims/${item.claimNumber}`}
                              style={{ textDecoration: 'none' }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.claimNumber} <BsLink45Deg />
                            </a>
                          ) : (
                            <>{item.claimNumber}</>
                          )}
                        </span>
                      </td>
                    </tr>
                    {item.claimlinesHistory ? (
                      item.claimlinesHistory.map(
                        (claimlinesHistory: IClaimlinesHistory) => {
                          return (
                            <>
                              <tr>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-line-number-${item.claimNumber}`}
                                >
                                  LINE
                                  <br />
                                  NUMBER
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-transaction-date-${item.claimNumber}`}
                                >
                                  TRANSACTION
                                  <br />
                                  DATE
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-submitted-proc-code-${item.claimNumber}`}
                                >
                                  SUBMITTED
                                  <br />
                                  PROC CODE
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-paid-proc-code-${item.claimNumber}`}
                                >
                                  PAID
                                  <br />
                                  PROC CODE
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-predet-${item.claimNumber}`}
                                >
                                  PREDET
                                </th>
                                <td></td>
                              </tr>
                              <tr>
                                <td
                                  data-qa={`claimline-history-line-number-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.lineNumber}
                                </td>
                                <td
                                  data-qa={`claimline-history-service-date-${item.claimNumber}`}
                                >
                                  {formatDate(
                                    claimlinesHistory.serviceDate.toString(),
                                    true,
                                    'dot'
                                  )}
                                </td>
                                <td
                                  data-qa={`claimline-history-submitted-proc-code-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.submittedProcedureCode}
                                </td>
                                <td
                                  data-qa={`claimline-history-paid-proc-code-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.paidProcedureCode}
                                </td>
                                <td
                                  data-qa={`claimline-history-predet-${item.claimNumber}`}
                                >
                                  {item.claimType === '3' ||
                                  item.claimType === '5'
                                    ? 'Yes'
                                    : 'No'}
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-tooth-number-${item.claimNumber}`}
                                >
                                  TOOTH
                                  <br />
                                  NUMBER
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-tooth-surface-${item.claimNumber}`}
                                >
                                  TOOTH
                                  <br />
                                  SURFACE
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-submitted-fees-${item.claimNumber}`}
                                >
                                  SUBMITTED
                                  <br />
                                  FEES
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-paid-fees-${item.claimNumber}`}
                                >
                                  PAID
                                  <br />
                                  FEES
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-provider-${item.claimNumber}`}
                                >
                                  PROVIDER
                                </th>
                                <th
                                  style={{ fontWeight: 600 }}
                                  data-qa={`claimline-history-header-comments-${item.claimNumber}`}
                                >
                                  COMMENTS
                                </th>
                              </tr>
                              <tr
                                style={{
                                  borderBottom: '4px solid rgb(45, 176, 53)',
                                }}
                              >
                                <td
                                  data-qa={`claimline-history-tooth-number-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.toothNumber}
                                </td>
                                <td
                                  data-qa={`claimline-history-tooth-surface-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.toothSurface}
                                </td>
                                <td
                                  data-qa={`claimline-history-submitted-fee-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.submittedFee}
                                </td>
                                <td
                                  data-qa={`claimline-history-paid-fee-${item.claimNumber}`}
                                >
                                  {claimlinesHistory.paidFee}
                                </td>
                                <td
                                  data-qa={`claimline-history-rendering-provider-${item.claimNumber}`}
                                >
                                  {(item.renderingProviderFirstName ?? '') +
                                    (item.renderingProviderLastName
                                      ? ' ' + item.renderingProviderLastName
                                      : '')}
                                </td>
                                <td
                                  data-qa={`claimline-history-comment-${claimlinesHistory.lineNumber}`}
                                >
                                  {claimlinesHistory.comment}
                                </td>
                              </tr>
                            </>
                          )
                        }
                      )
                    ) : (
                      <tr>
                        <td>No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )
          })
        )
      ) : (
        <div>
          {token ? (
            <LinearProgress color="primary" />
          ) : (
            <div>No records found.</div>
          )}
        </div>
      )}
    </div>
  )
}

export default ClaimsDetailsHistoryTable
