import { createContext, useContext, useEffect, useState } from 'react'
import '../../sass/base/_global.scss'
import '../../sass/components/details/_details-review.scss'
import { formatDate } from '../../services/StringFormater'
import ClaimlineCommentButton from '../ClaimlineCommentButton'
import { ClaimDetailsClinicianFeedback } from './ClaimDetailsClinicianFeedback'
import Chip from '@mui/material/Chip'
import { AuthContext } from '../../auth/AuthProvider'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useLocation } from 'react-router-dom'
import React from 'react'

export const Context = createContext<any>({})

interface ReviewInfo {
  lineNumber: string
  createDate: string
  submitDate: string
  procedureCode: string
  toothNumber: string
  toothQuadArch: string
  recommendation: string
  criteria: string
  lineNumberId: number
  clinicalAgreement: string
  caasAdjudication: string
  aiProvider: string
  caasAdjudicationDowncode: string
  caasAdjudicationInformation: string
}

interface ClaimLine {
  lineNumber: string
  reviewResult: string
  id: number
  submitDate: string
  clinicalAgreement: string
  caasAdjudication: string
  aiProvider: string
  analysisResults: any[]
  caasAdjudicationDowncode: string
  caasAdjudicationInformation: string
}

const genId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

const ClaimDetailsReviewInfoCard = (props: {
  claimDetailsData: any
  reviewInfoList: ReviewInfo[]
  claimLines: ClaimLine[]
}) => {
  const authContext = useContext(AuthContext)
  const [isUserCaas, setUserCass] = useState(
    authContext.isUserCaas(authContext.cognitoAuth)
  )
  const { search } = useLocation();
  const searchParam =  React.useMemo(() => new URLSearchParams(search), [search]);
  const { reviewInfoList, claimLines } = props
  reviewInfoList.forEach((item) => {
    claimLines.forEach((line) => {
      if (item.lineNumber == line.lineNumber) {
        item.recommendation = line.reviewResult
        item.lineNumberId = line.id
        item.submitDate = formatDate(line.submitDate, true, 'dot')
        item.clinicalAgreement = line.clinicalAgreement
        item.caasAdjudication = line.caasAdjudication
        item.aiProvider = line.aiProvider
        item.caasAdjudicationDowncode = line.caasAdjudicationDowncode
        item.caasAdjudicationInformation = line.caasAdjudicationInformation
      }
    })
  })

  const isDeniedText = (textInQuestion: string) => {
    return textInQuestion === 'Deny' || textInQuestion?.includes('posterior')
  }
  
  const convertDateToUTC = (date: Date) => {
    const timestamp = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    );
  
    return new Date(timestamp);
  }

  const isAnyClaimLineCaasAdjudicationUpdateDateNull = () => {
    let flag = false;
    let claimLinesArray = Array.from(props.claimDetailsData.claimlines)
    claimLinesArray.forEach((claimline : any) =>{
      if(claimline.caasAdjudicationUpdateDate == null)
        flag = true;
    })
    return flag
  }

  const getEarliestClaimLineCaasAdjudicationUpdateDate = () => {
    let updateDate = 0;
    let claimLinesArray = Array.from(props.claimDetailsData.claimlines)
    claimLinesArray.forEach((claimline : any) => {
      
      if(claimline.caasAdjudicationUpdateDate == null)
        return 0;

      let caasAdjudicationUpdateDateMillis = convertDateToUTC(new Date(claimline.caasAdjudicationUpdateDate)).getTime()
      
      if(caasAdjudicationUpdateDateMillis > updateDate)
        updateDate = caasAdjudicationUpdateDateMillis
    })
    return updateDate
  }

  const isClaimEditable = () => {
    if(props.claimDetailsData.processingStatus === 'Sent To CAAS' || 
      (props.claimDetailsData.processingStatus === 'Complete' &&  ((new Date().getTime() - getEarliestClaimLineCaasAdjudicationUpdateDate()) / (1000 * 60) < 5)))
      return true
    return false
  }

  const isCaasAdjudicationDisplayed = () => {
    if(props.claimDetailsData.processingStatus === 'Complete' &&
      !isAnyClaimLineCaasAdjudicationUpdateDateNull() &&
      ((new Date().getTime() - getEarliestClaimLineCaasAdjudicationUpdateDate()) / (1000 * 60) > 5))
      return true
    return false
  }

  const chipStyle = {
    marginLeft: '10px',
    borderColor: '#000',
    fontWeight: 600,
    fontSize: '12px',
    textTransform: 'uppercase' as 'uppercase',
  }

  const renderFeedbackLabelColumnByRole = (recommendation : string) => {
    if (isUserCaas && searchParam.get("clinician") == "javalin")
    {
      if (isClaimEditable() || isCaasAdjudicationDisplayed())
      {
        return (
          <span className="label">
            Caas <br />
            Adjudication
          </span>
        )
      }
    }
    else if(props.claimDetailsData.processingStatus === 'Complete' && recommendation !== "NoAiReview")
      {
        return (
          <span className="label">
            Clinician <br />
            Feedback
          </span>
        )
      }
  }

  const renderReviewInfoCard = () => {
    return reviewInfoList.map(
      (
        reviewInfo: {
          lineNumber: string
          createDate: string
          submitDate: string
          procedureCode: string
          toothNumber: string
          toothQuadArch: string
          recommendation: string
          criteria: string
          lineNumberId: number
          clinicalAgreement: string
          caasAdjudication: string
          aiProvider: string
          caasAdjudicationDowncode: string
          caasAdjudicationInformation: string
        },
        index: number
      ) => {
        const {
          lineNumber,
          createDate,
          submitDate,
          procedureCode,
          toothNumber,
          toothQuadArch,
          recommendation,
          criteria,
          lineNumberId,
          clinicalAgreement,
          caasAdjudication,
          aiProvider,
          caasAdjudicationDowncode,
          caasAdjudicationInformation
        } = reviewInfo

        const clinicalCriteria = claimLines[index].analysisResults

        const clinicalCriteriaByToothNumber: { [key: string]: any[] } = {}
        clinicalCriteria.forEach((element: any) => {
          const toothNumber = element.toothNumber !== null ? element.toothNumber : 'n/a';
          if (!clinicalCriteriaByToothNumber[toothNumber]) {
            clinicalCriteriaByToothNumber[toothNumber] = []
          }
          clinicalCriteriaByToothNumber[toothNumber].push(element)
        })

        const displayClinicalCriteria = clinicalCriteria
          ? Object.entries(clinicalCriteriaByToothNumber).map(
              ([toothNumber, criteriaGroup], index) => (
                <div className='item-clinical-criteria' key={toothNumber} data-qa={'div-ClinicalCriteriaItem' + '-' + index}>
                  <p className="heading" data-qa={'div-ClinicalCriteriaItemHeading' + '-' + index}>Tooth Number: {toothNumber}</p>
                  <div className="sub-heading" data-qa={'div-ClinicalCriteriaItemSubHeading' + '-' + index}>
                    {criteriaGroup.map((element: any, elementIndex: number) => (
                      <p key={`${toothNumber}-${elementIndex}`}>
                        <strong>{element.condition}</strong> - {element.value}
                      </p>
                    ))}
                  </div>
                </div>
              )
            )
          : null

        const [showClinicalCriteria, setShowClinicalCriteria] = useState(false)

        const toggleClinicalCriteria = () => {
          setShowClinicalCriteria(!showClinicalCriteria)
        }

        return (
          <Context.Provider value={{ lineNumberId, lineNumber }} key={genId()}>
            <div
              className="review-line-item"
              data-qa={'tr-' + index}
              key={index}
            >
              <div className="row-1">
                <div className="line-1">
                  <p data-qa={'td-SubmitDate' + '-' + index} className="date">
                    {submitDate}
                  </p>
                  <p data-qa={'td-Line' + '-' + index} className="line-number">
                    Line <span>{lineNumber}</span>
                  </p>
                </div>
                  <div
                    data-qa={'td-ClinicalAgreement' + '-' + index}
                    className="clinicial-feedback"
                  >
                    {renderFeedbackLabelColumnByRole(recommendation)}
                    <ClaimDetailsClinicianFeedback
                      isClaimEditable={isClaimEditable()}
                      isCaasAdjudicationDisplayed={isCaasAdjudicationDisplayed()}
                      isClaimCompleted={props.claimDetailsData.processingStatus === 'Complete'}
                      recommendation={recommendation}
                      claimLineId={lineNumberId}
                      clinicalAgreement={clinicalAgreement}
                      caasAdjudication={caasAdjudication}
                      caasAdjudicationDowncode={caasAdjudicationDowncode}
                      caasAdjudicationInformation={caasAdjudicationInformation}
                      tenantAcronym={props.claimDetailsData.tenantAcronym}
                    />
                  </div>
              </div>
              <div className="line-separator"></div>
              <div className="table-view">
                <p data-qa={'td-ProcedureCode' + '-' + index}>
                  <span className="table-h">Procedure code</span>{' '}
                  {procedureCode}
                </p>
                <p data-qa={'td-ToothNumber' + '-' + index}>
                  <span className="table-h">Tooth</span>
                  {toothNumber} {toothQuadArch}
                </p>

                <p
                  className={
                    isDeniedText(recommendation) ? 'error-txt' : 'valid-txt'
                  }
                  data-qa={'td-JavalinRecommendation' + '-' + index}
                >
                  <span className="table-h">Javalin Recommendation</span>
                  <div>
                    {
                      {
                        NoAiReview: 'No Ai Review',
                        Denied: 'Denied',
                        Accepted: 'Accepted',
                        PartiallyDenied: 'Partially Denied',
                        BadInput: 'Bad Input',
                        PendingInternalReview: 'Pending Internal Review',
                        None: 'None',
                        Inconclusive: 'Inconclusive',
                        DeniedWithRecommendation: 'Denied With Recommendation',
                        Failed: 'Failed',
                      }[recommendation]
                    }

                    {aiProvider && (
                      <Chip
                        label={
                          <div data-qa="ai-provider-label">{aiProvider}</div>
                        }
                        variant="outlined"
                        style={chipStyle}
                      />
                    )}
                  </div>
                </p>
              </div>
              <div className="line-separator"></div>
              <div
                className={`last-row ${
                  displayClinicalCriteria && displayClinicalCriteria.length > 0
                    ? 'clinical-criteria'
                    : ''
                }`}
              >
                {displayClinicalCriteria && displayClinicalCriteria.length > 0 && (
                  <div className="clinical-criteria-container" data-qa={'div-ClinicalCriteriaContainer' + '-' + index}>
                    <div
                      className="clinical-criteria-header"
                      onClick={toggleClinicalCriteria}
                      data-qa={'div-ClinicalCriteriaHeader' + '-' + index}
                    >
                      Clinical Criteria{' '}
                      {showClinicalCriteria ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                    {showClinicalCriteria && (
                      <div className="clinical-criteria-body" data-qa={'div-ClinicalCriteriaBody' + '-' + index}>
                        {displayClinicalCriteria}
                      </div>
                    )}
                  </div>
                )}
                <p
                  data-qa={'td-CommentButton' + '-' + index}
                  style={{
                    marginBottom: '0px',
                  }}
                >
                  <ClaimlineCommentButton
                    claimDetailsData={props.claimDetailsData}
                  />
                </p>
              </div>
            </div>
            <div>
              <p
                className={
                  isDeniedText(recommendation) ? 'error-txt' : 'valid-txt'
                }
                data-qa={'td-' + criteria + '-' + index}
              >
                {criteria}
              </p>
            </div>
          </Context.Provider>
        )
      }
    )
  }

  return (
    <div className="reviewInfoContainer">
      <div id="reviewInfoCard">{renderReviewInfoCard()}</div>
    </div>
  )
}

export default ClaimDetailsReviewInfoCard
