import { useEffect, useState } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium'
import { Button } from '@mui/material'
import { ClaimsFilter } from '../models/ClaimsFilter'
import { useNavigate } from 'react-router-dom'
import { IoSearchSharp } from 'react-icons/io5'

type CustomDataGridMenuProps = {
  claimsFilter: ClaimsFilter
  handleSearchInput: (query: string) => void
  activeFiltersCount: number
}

const CustomDataGridMenu = ({
  claimsFilter,
  handleSearchInput,
  activeFiltersCount,
}: CustomDataGridMenuProps) => {
  const [searchInput, setSearchInput] = useState('Search...')
  const navigate = useNavigate()

  const containerStyles = {
    borderRadius: '4px',
    backgroundColor: '#e9e9e9',
    display: 'flex',
    alignItems: 'center',
  }

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      setSearchInput('')
      navigate(`/search?searchTerm=${searchInput}`)
    }
  }

  const handleOnClick = () => {
    setTimeout(() => {
      applyStylesToElement(
        '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1',
        'position: relative; margin-left: -70px; top: 30px;'
      )
    }, 50)
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#e9e9e9',
      }}
    >
      <div style={{ justifyContent: 'flex-start' }}>
        <div style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
          <CustomToolbarFilterButton numberOfFilters={activeFiltersCount} />
          <div
            style={{
              flexDirection: 'row',
              borderRadius: '4px',
            }}
          >
            <div style={containerStyles}>
              <IoSearchSharp
                style={{
                  borderBlock: 'none',
                  lineHeight: '34px',
                  fontSize: '24px',
                  color: '#777777',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '10px',
                }}
              />
              <input
                type="text"
                style={{
                  border: '0',
                  paddingLeft: '6px',
                  borderRadius: '4px',
                  borderBlock: 'none',
                  lineHeight: '34px',
                  fontSize: '20px',
                  backgroundColor: '#e9e9e9',
                  color: '#777777',
                  display: 'flex',
                }}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={handleKeyPress}
                onFocus={(e) => {
                  searchInput === '' || searchInput === 'Search...'
                    ? setSearchInput('')
                    : e.target.value
                }}
                onBlur={(e) => {
                  setSearchInput(
                    e.target.value === '' ? 'Search...' : e.target.value
                  )
                }}
                data-qa="search-input"
                className="search-input"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ justifyContent: 'flex-end' }}>
        <GridToolbarContainer sx={{ margin: '20px' }}>
          <div>
            <GridToolbarColumnsButton
              sx={{ fontWeight: '700' }}
              data-qa="columns-button"
            />
            <GridToolbarDensitySelector
              onClick={handleOnClick}
              sx={{ fontWeight: '700' }}
              data-qa="density-button"
            />
            <GridToolbarExport
              sx={{ fontWeight: '700' }}
              data-qa="export-0`"
              printOptions={{ disableToolbarButton: true }}
            ></GridToolbarExport>
          </div>
        </GridToolbarContainer>
      </div>
    </div>
  )
}

export default CustomDataGridMenu

import { useGridApiContext } from '@mui/x-data-grid'
import { MdOutlineFilterAlt } from 'react-icons/md'
import { Badge } from '@mui/material'
import { applyStylesToElement } from './Utils'

interface CustomToolbarFilterButtonProps {
  numberOfFilters: number
}

const CustomToolbarFilterButton: React.FC<CustomToolbarFilterButtonProps> = ({
  numberOfFilters,
}) => {
  const apiRef = useGridApiContext()
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false)

  const handleOpenFilterPanel = () => {
    if (isFilterPanelOpen) {
      apiRef.current.hideFilterPanel()
    } else {
      apiRef.current.showFilterPanel()
    }
    setIsFilterPanelOpen(!isFilterPanelOpen)
  }

  const activeFiltersCount = numberOfFilters

  return (
    <Button
      onClick={handleOpenFilterPanel}
      sx={{
        backgroundColor: '#2db035',
        marginRight: '10px',
        color: '#ffffff',
        fontWeight: '700',
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: '#1f7b25',
        },
      }}
      data-qa="button-filters"
    >
      <MdOutlineFilterAlt style={{ fontSize: '20px' }} />
      <span style={{ margin: '0 10px' }}>Filters</span>

      {activeFiltersCount !== 0 ? (
        <span
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            lineHeight: '20px',
            color: '#000000',
          }}
        >
          <span data-qa="button-filters-active-count">
            {activeFiltersCount}
          </span>
        </span>
      ) : null}
    </Button>
  )
}
