import '../../sass/base/_global.scss'
import '../../sass/components/details/_details-data-line.scss'
import { formatDate } from '../../services/StringFormater'
import { Paper } from '@mui/material'
import { useState } from 'react'

interface ClaimDetailsData {
  id: string
  claimNumber: string
  submitDate: string
  renderingProviderFirstName: string
  renderingProviderLastName: string
  groupName: string
  patientAge: string
  clinicalAgreement: string
  hasDualCoverage: boolean
  hasPredet: boolean
  processingStatus: string
  processingStatusCode: string
  processingStatusDetails: string
  appealCount: string
  isAppeal: boolean | null
}

const ClaimDetailsDataLine = (props: {
  claimDetailsData: ClaimDetailsData
}) => {
  const { claimDetailsData } = props
  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = () => {
    setShowDetails(!showDetails)
  }

  const determineRenderingProvider = () => {
    return claimDetailsData.renderingProviderFirstName &&
      claimDetailsData.renderingProviderLastName
      ? `${claimDetailsData.renderingProviderFirstName} ${claimDetailsData.renderingProviderLastName}`
      : `${
          claimDetailsData.renderingProviderFirstName ||
          claimDetailsData.renderingProviderLastName ||
          'n/a'
        }`
  }

  const determineAppealCount = () => {
    if(claimDetailsData.appealCount == null)
      return 'n/a'
    else
      return claimDetailsData.appealCount
  }

  const style: {
    headerCard: React.CSSProperties
    h4: React.CSSProperties
    claimStatusContainer: React.CSSProperties
  } = {
    headerCard: {
      padding: 2,
      margin: '10px 10px 0 10px',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '5px',
      backgroundColor: '#caebcc',
      width: '28%',
      overflowX: 'auto',
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontFamily: 'Gotham,"Segoe UI Bold",Roboto,Geneva,Verdana,sans-serif',
      textTransform: 'uppercase',
    },
    claimStatusContainer: {
      width: '32%',
      backgroundColor: '#f0f9ee',
    },
  }

  const statusContainerStyle = {
    complete: claimDetailsData.processingStatus === 'Complete',
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#f0f9ee',
        padding: '10px 10px 20px 10px',
      }}
    >
      <Paper elevation={0} sx={style.claimStatusContainer}>
        <div data-qa={'div-claim-info'}>
          <p className="submit-date" data-qa={'div-submit-date'}>
            Submit Date:{' '}
            <span data-qa={'div-submit-date-value'}>
              {formatDate(claimDetailsData.submitDate, true, 'dot')}
            </span>
          </p>
          <div className="claim-number" data-qa={'div-claim-number'}>
            <p>
              <span className="claim-label">Claim</span>
              <span
                title={`Claim ID: ${claimDetailsData.id}`}
                style={{
                  cursor: 'pointer',
                  fontWeight: 700,
                }}
                data-qa={'div-claim-number-value'}
                onClick={() =>
                  navigator.clipboard.writeText(claimDetailsData.claimNumber)
                }
              >
                <a style={{ textDecoration: 'none' }} href="#">
                  {claimDetailsData.claimNumber}
                </a>
              </span>
            </p>
          </div>
          <div className="status-row" data-qa={'div-claim-status-row'}>
            <div
              className={`status-container ${
                statusContainerStyle.complete ? 'complete' : ''
              }`}
              data-qa={'div-claim-status-container'}
            >
              <div className="status-header-toggle">
                <div className="status-value" data-qa={'div-claim-status'}>
                  {' '}
                  Status:
                  <span
                    style={{
                      marginLeft: '5px',
                    }}
                  >
                    {claimDetailsData.processingStatus}
                  </span>
                </div>
                {claimDetailsData.processingStatusDetails && (
                  <div
                    className="status-details-button"
                    data-qa={'div-claim-status-details-button'}
                    onClick={toggleDetails}
                  >
                    {showDetails ? 'Hide Details' : 'Show Details'}
                  </div>
                )}
              </div>
              {showDetails && (
                <div
                  className="status-details"
                  data-qa={'div-claim-status-details'}
                >
                  {claimDetailsData.processingStatusDetails}
                </div>
              )}
            </div>
            {claimDetailsData.processingStatusCode && (
              <div className="status-code" data-qa={'div-claim-status-code'}>
                CODE: {claimDetailsData.processingStatusCode}
              </div>
            )}
          </div>
        </div>
      </Paper>
      <div className="stacked-cards">
        <Paper elevation={0} className="tiny-card">
          <div data-qa={'div-appeal-count'}>
            <h4 style={style.h4}>Appeal Count 
              <span data-qa={'span-appeal-count-value'} style={{paddingLeft:10}}>
                {determineAppealCount()}
              </span>
            </h4>
          </div>
        </Paper>
        <Paper elevation={0} className="tiny-card">
          <div  data-qa={'div-patient-age'}>
            <h4 style={style.h4}>Patient Age 
              <span data-qa={'span-patient-age-value'} style={{paddingLeft:10}}>
                {claimDetailsData.patientAge}
              </span>
            </h4>
          </div>
        </Paper>
      </div>
      <Paper elevation={0} sx={style.headerCard}>
        <div data-qa={'div-rendering-provider'}>
          <h4 style={style.h4}>Rendering Provider</h4>
          <span data-qa={'div-rendering-provider-value'}>
            {determineRenderingProvider()}
          </span>
        </div>
      </Paper>
      <Paper elevation={0} sx={style.headerCard}>
        <div data-qa={'div-group-name'}>
          <h4 style={style.h4}>Group Name</h4>
          <span data-qa={'div-group-name-value'}>
            {claimDetailsData.groupName !== null
              ? claimDetailsData.groupName
              : 'n/a'}
          </span>
        </div>
      </Paper>
      <div style={{ display: 'flex' }}>
        {claimDetailsData.hasDualCoverage ? (
          <div
            className="indicator-flag dual first-item"
            data-qa="indicator-flag-dual"
          >
            DUAL
          </div>
        ) : null}
        {claimDetailsData.hasPredet ? (
          <div
            className="indicator-flag predet other-item"
            data-qa="indicator-flag-predet"
          >
            PREDET
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default ClaimDetailsDataLine
