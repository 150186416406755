/* eslint-disable no-tabs */
import { ClaimLine } from '../ClaimLine'
import { IApiResponse } from './IApiResponse'
import { IClaim } from './IClaim'

export class GetClaimResponse implements IApiResponse, IClaim {
  apiRequestStatusText!: string
  apiRequestStatusNumber?: number
  claimId!: number
  claimNumber!: string
  status!: string
  groupName!: string
  renderingProvider!: string
  submitDate!: Date
  narrative!: string
  concatenatedNarrative!: string
  tenantAcronym!: string
  claimlines!: ClaimLine[]
}
