export const ClinicalAgreementOptions = {
  //agreement options tenant
  AGREED: 'Agreed',
  DISAGREED: 'Disagreed',
  INSUFFICIENTDATA: 'InsufficientData',
  
  //agreement options javalin
  APPROVE: 'Approve',
  DENY: 'Deny',
  PARTIALAPPROVAL: 'PartialApproval',
  REQUESTADDITIONALINFORMATION: 'RequestAdditionalInformation',
  
  UNDEFINED: 'undefined',
  NONE: 'None'
}
