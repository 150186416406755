import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth/AuthProvider'

const RequireAuth = ({ children }: { children: any }) => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const checkAuth = async () => {
      const isUserSignedIn = await authContext.cognitoAuth.isUserSignedIn()

      if (!isUserSignedIn) {
        localStorage.setItem(
          'requestedUrl',
          window.location.pathname + window.location.search
        )
        navigate('/login', { replace: true })
      }
    }

    checkAuth()
  }, [authContext.cognitoAuth, navigate])

  return authContext.cognitoAuth.isUserSignedIn() ? children : null
}

export default RequireAuth
