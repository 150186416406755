import { useContext, useEffect, useState } from 'react'
import { RxAvatar } from 'react-icons/rx'
import { VscHome } from 'react-icons/vsc'
import {
  Menu,
  MenuItem,
  Tooltip,
  Box,
  Button,
  ListItemIcon,
} from '@mui/material'
import Logout from '@mui/icons-material/Logout'
import '../sass/base/_global.scss'
import '../sass/components/header/_header.scss'
import { AuthContext } from '../auth/AuthProvider'
import React from 'react'
import { useLocation } from 'react-router-dom'

const Header = () => {
  const authContext = useContext(AuthContext)
  const tenantIdPlaceHolder = '<empty tenantId>'

  const [userName, setUserName] = useState(
    authContext.getUserDisplayName(authContext.cognitoAuth)
  )
  const [tenantId, setTenantId] = useState(tenantIdPlaceHolder)

  const [isUserCaas, setUserCass] = useState(
    authContext.isUserCaas(authContext.cognitoAuth)
  )
  const { search } = useLocation()
  const searchParam =  React.useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    setUserName(`${authContext.getUserDisplayName(authContext.cognitoAuth)}`)
    setTenantId(`${authContext.getTenantId(authContext.cognitoAuth)}`)
    setUserCass(authContext.isUserCaas(authContext.cognitoAuth))
  }, [authContext, userName, tenantId, isUserCaas])

  const handleItemClick = () => {
    authContext.cognitoAuth.signOut()
    localStorage.removeItem('hasHistory')
  }

  const HeaderMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <Box>
          {authContext.cognitoAuth.isUserSignedIn() ? (
            <Tooltip title="Logout ↓">
              <Button
                onClick={handleClick}
                variant="text"
                sx={{
                  backgroundColor: '#80c275',
                  color: '#ffffff',
                  fontWeight: '900',
                  padding: '4px 8px',
                  fontFamily:
                    'Gotham,"Segoe UI Bold",Roboto,Geneva,Verdana,sans-serif',
                }}
              >
                <RxAvatar
                  style={{ width: '22px', height: '22px', marginRight: '8px' }}
                />
                <span data-qa="header-username">{userName}</span>
              </Button>
            </Tooltip>
          ) : null}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          className="menu-link"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          disableScrollLock={true}
        >
          <MenuItem onClick={handleItemClick}>
            <ListItemIcon>
              <Logout fontSize="small" />
              <span data-qa="sign-out">Logout</span>
            </ListItemIcon>
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <div
      style={{
        backgroundColor: '#2DB035',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '56px',
        paddingRight: '32px',
      }}
    >
      <span
        style={{
          color: '#ffffff',
          fontSize: '1em',
          letterSpacing: '0.15px',
          marginLeft: '32px',
          fontFamily: 'Gotham,"Segoe UI",Roboto,Geneva,Verdana,sans-serif',
        }}
      >
        {authContext.cognitoAuth.isUserSignedIn() ? (
          <Button
            sx={{
              backgroundColor: window.location.pathname.startsWith('/caas') || searchParam.get("clinician") === "javalin"
                ? 'transparent'
                : '#80c275',
              color: '#ffffff',
              fontWeight: '900',
              padding: '4px 8px',
              fontFamily:
                'Gotham,"Segoe UI Bold",Roboto,Geneva,Verdana,sans-serif',
            }}
            href="/claims"
            style={{ textDecoration: 'none' }}
          >
            <VscHome
              style={{ width: '22px', height: '22px', marginRight: '8px' }}
            />{' '}
            <span data-qa="home-button">HOME</span>
          </Button>
        ) : null}
        {isUserCaas ? (
          <Button
            sx={{
              backgroundColor: window.location.pathname.startsWith('/caas') || searchParam.get("clinician") === "javalin"
                ? '#80c275'
                : 'transparent',
              color: '#ffffff',
              fontWeight: '900',
              padding: '4px 8px',
              fontFamily:
                'Gotham,"Segoe UI Bold",Roboto,Geneva,Verdana,sans-serif',
            }}
            href="/caas/tenants"
            style={{ textDecoration: 'none' }}
          >
            <VscHome
              style={{ width: '22px', height: '22px', marginRight: '8px' }}
            />{' '}
            <span data-qa="caas-button">CAAS</span>
          </Button>
        ) : null}
      </span>

      <span
        style={{
          fontFamily: 'Gotham,"Segoe UI Bold",Roboto,Geneva,Verdana,sans-serif',
          fontWeight: 'bold',
          fontSize: '22px',
          color: '#ffffff',
        }}
        data-qa="header-brand"
      >
        JAVALIN DENTAL
      </span>
      <div>
        {authContext.cognitoAuth.isUserSignedIn() ||
        (window.location.pathname !== '/login' &&
          window.location.pathname !== '/') ? (
          <HeaderMenu />
        ) : null}
      </div>
    </div>
  )
}

export default Header
