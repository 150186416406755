import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { cognitoAuth } from '../../auth/AuthProvider'
import { AxiosError } from 'axios'

type AxiosMockError = Error & {
  mockData?: AxiosResponse
  config: InternalAxiosRequestConfig
}

let mockingEnabled: boolean = false

const mocks: { [name: string]: AxiosResponse } = {}

export function addMock(url: string, data: AxiosResponse) {
  mocks[url] = data
}

export function enableMocking(state: boolean) {
  mockingEnabled = state
}

const isUrlMocked = (url?: string) => {
  if (typeof url === 'undefined') return false
  return url in mocks
}
const getMockError = (config: InternalAxiosRequestConfig) => {
  if (typeof config.url == 'undefined') return Promise.reject()
  const mockError: AxiosMockError = {
    mockData: mocks[config.url],
    config: config,
    name: '',
    message: '',
  }
  return Promise.reject(mockError)
}

const isMockError = (error: AxiosMockError) => Boolean(error.mockData)

const getMockResponse = (mockError: AxiosMockError) => {
  const { mockData, config } = mockError
  if (typeof mockData === 'undefined') return

  mockData.config = config

  // Handle mocked error (any non-2xx status code)
  if (mockData.status && String(mockData.status)[0] !== '2') {
    const err = new AxiosError(mockData.data.message || 'mock error')
    err.code = mockData.status.toString()
    return Promise.reject(err)
  }
  // Handle mocked success
  return Promise.resolve(
    Object.assign(
      {
        data: {},
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        isMock: true,
      },
      mockData
    )
  )
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_USER_URL,
})

api.interceptors.request.use(async (config) => {
  try {
    config.headers['Content-Type'] = 'application/json'
    config.headers.Accept = 'application/json'
    config.headers.Authorization =
      'Bearer ' +
      cognitoAuth.getSignInUserSession().getAccessToken().getJwtToken()
    config.headers.Identity = cognitoAuth
      .getSignInUserSession()
      .getIdToken()
      .getJwtToken()
    if (mockingEnabled && isUrlMocked(config.url)) {
      return getMockError(config)
    }
  } catch (e) {
    console.log(e)
  }
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isMockError(error)) {
      return getMockResponse(error)
    }

    return Promise.reject(error)
  }
)

export default api
